import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../../services/userStore";
import AWSRequestResourceAccessForm from "./AWSRequestResourceAccessForm";

const AccessEc2Resources = ({
  publishedEc2Instances,
  setPublishedEc2Instances,
  user,
}) => {
  const [selectedResource, setSelectedResource] = useState(null);
  const [onAWSRequestResourceAccessForm, setOnAWSRequestResourceAccessForm] =
    useState(false);
  const { role, userId } = useUserStore((state) => state.user);
  const handleClickRequestForm = (ec2) => {
    setSelectedResource(ec2);
    setOnAWSRequestResourceAccessForm(true);
  };
  useEffect(() => {}, [publishedEc2Instances]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "16px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Status</TableCell>
              {role === "TENANT_USER" ? <TableCell>Action</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {publishedEc2Instances?.length > 0 ? (
              publishedEc2Instances.map((ec2, index) => (
                <TableRow key={index}>
                  <TableCell>{ec2.instanceName}</TableCell>
                  <TableCell>{ec2.availabilityZone}</TableCell>
                  <TableCell>{ec2.instanceState}</TableCell>
                  {role === "TENANT_USER" ? (
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClickRequestForm(ec2)}
                      >
                        Request Access
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body2">
                    No Resource available.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedResource && onAWSRequestResourceAccessForm && (
        <AWSRequestResourceAccessForm
          open={onAWSRequestResourceAccessForm}
          onClose={() => {
            setOnAWSRequestResourceAccessForm(false);
            setSelectedResource(null);
          }}
          data={selectedResource}
          title={selectedResource?.instanceName}
          user={user}
        />
      )}
    </>
  );
};

export default AccessEc2Resources;
