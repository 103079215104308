import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import SelectOS from "../../Components/SelectOS";
import ProfileSelection from "./ProfileSelection";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const CreateProfileDialog = ({ profileList, fetchPendingProfiles }) => {
  const [open, setOpen] = useState(false);
  const [selectedOS, setSelectedOS] = useState("Windows");
  const [profileName, setProfileName] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNameChange = (name) => {
    setProfileName(name);
    setNameErrorMessage("");
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProfile(null);
    setProfileName("");
    setNameErrorMessage("");
  };

  const createPendingProfile = async (profileId, newProfileName) => {
    try {
      const { data: success } = await axios.post(
        `${NGROK}/api/profiles/create-pending`,
        {
          copyFromProfileId: profileId,
          newProfileName,
        }
      );
      return success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const copyProfile = async (profileId, newProfileName) => {
    try {
      const { data: success } = await axios.post(`${NGROK}/api/profiles/copy`, {
        copyFromProfileId: profileId,
        newProfileName,
      });
      return success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleSubmit = async () => {
    const isProfileALreadyCreated = profileList.some(
      (profile) => profile.name === profileName
    );

    if (isProfileALreadyCreated) {
      setNameErrorMessage("Profile with this name already exists.");
      return;
    }
    try {
      const isPendingProfileCreated = await createPendingProfile(
        selectedProfile?.id,
        profileName
      );

      if (isPendingProfileCreated) {
        fetchPendingProfiles();
        copyProfile(selectedProfile?.id, profileName);
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create new profile
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Create new profile"}
        </DialogTitle>
        <DialogContent>
          <Stack width={400} spacing={4}>
            <Box
              height={60}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"end"}
            >
              <TextField
                required
                autoFocus
                id="name"
                label="Profile name"
                type="text"
                fullWidth
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                onChange={(e) => handleNameChange(e.target.value)}
                sx={{ height: 45 }}
              />
            </Box>
            {nameErrorMessage ? (
              <Alert sx={{ marginTop: 1 }} severity="error">
                {nameErrorMessage}
              </Alert>
            ) : null}
            <Box display={"flex"} justifyContent={"center"}>
              {" "}
              <SelectOS selectedOS={selectedOS} setSelectedOS={setSelectedOS} />
            </Box>

            <ProfileSelection
              profileList={profileList}
              selectedOS={selectedOS}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!profileName || !selectedProfile}
            onClick={handleSubmit}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateProfileDialog;
