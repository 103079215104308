import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";

const RadioFilter = ({ handleFilterResourceValue, filterValue }) => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <FormControl sx={{ minWidth: "200px" }}>
        {/* <FormLabel id="demo-radio-buttons-group-label">{filterName}</FormLabel> */}
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row" }}
          value={filterValue}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Show All"
            control={<Radio onChange={handleFilterResourceValue} />}
            label="Show All"
          />
          <FormControlLabel
            value="Agent Installed"
            control={<Radio onChange={handleFilterResourceValue} />}
            label="Agent Installed"
          />
          <FormControlLabel
            value="Agent not installed"
            control={<Radio onChange={handleFilterResourceValue} />}
            label="Agent not installed"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RadioFilter;
