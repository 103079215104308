import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

const OuSelection = ({ tenantName, setSelectedOU, selectedOU }) => {
  const [ouList, setOuList] = useState([]);

  const handleOuSelection = (ouName) => {
    if (!ouName) setSelectedOU(null);
    else {
      const ou = ouList?.find((ou) => ou.name === ouName);
      setSelectedOU(ou);
    }
  };

  useEffect(() => {
    const fetchOrganizationUnits = async () => {
      try {
        const response = await axios.get(
          `${NGROK}/api/${tenantName}/organization-units`
        );
        setOuList(response.data);
      } catch (error) {
        setOuList([]);
        console.error(error);
      }
    };

    fetchOrganizationUnits();
  }, [setOuList, tenantName]);

  return (
    <Box>
      {ouList?.length ? (
        <Autocomplete
          sx={{ paddingTop: 2 }}
          fullWidth
          disablePortal
          id="combo-box-demo"
          value={selectedOU?.name || null}
          options={ouList?.map((ou) => ou.name)}
          renderInput={(params) => (
            <TextField {...params} label={"Select organization unit"} />
          )}
          onChange={(e, value) => handleOuSelection(value)}
        />
      ) : (
        <Typography align="center" fontSize={14}>
          There are no organization units
        </Typography>
      )}
    </Box>
  );
};

export default OuSelection;
