import React, { useState } from "react";
import axios from "axios";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { NGROK } from "../../../../APIs";
import { DialogContentText } from "@mui/material";

const ConfirmDeleteDialog = ({
  category,
  setCategoryList,
  resourceType,
  os,
  selectedTenantName,
  setSelectedCategory,
  selectedCategory,
}) => {
  const [open, setOpen] = useState(false);

  const deleteGlobalCategory = async (categoryName) => {
    try {
      await axios.delete(
        `${NGROK}/api/category/${categoryName}/${resourceType.toUpperCase()}/${os}/${selectedTenantName}`
      );
      setCategoryList((prev) => prev.filter((el) => el.name !== categoryName));
    } catch {
      console.error("error during updating categories");
    } finally {
      handleClose();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="error"
        onClick={handleClickOpen}
        sx={category.name === selectedCategory?.name ? { color: "white" } : {}}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to proceed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Resources belonging to the category will continue to retain current
            trust level if they do not belong to another category.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              deleteGlobalCategory(category.name);
              setSelectedCategory(null);
              e.stopPropagation();
            }}
            variant="outlined"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmDeleteDialog;
