import React from "react";

import { TableCell, TableHead, TableRow } from "@mui/material";

const DetailsTableHead = () => {
  return (
    <TableHead
      sx={{
        backgroundColor: "#233044",
      }}
    >
      <TableRow>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Tenant
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Number of computers
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Number of users
        </TableCell>
        <TableCell
          colSpan={1}
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Api key
        </TableCell>
        <TableCell
          colSpan={2}
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Api key Management
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Tenant Management
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default DetailsTableHead;
