import React, { useState } from "react";

import { Stack } from "@mui/material";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";
import UsersSelectionList from "./components/UsersSelectionList";
import GroupsTable from "./components/GroupsTable";

const UserMembership = () => {
  const tenant = myLocalStorage.getItem("latestTenant");

  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [selectedUser, setSelectedUser] = useState(null);


  return (
    <Stack spacing={4}>
      <Stack direction={"row"} spacing={2}>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
      </Stack>
      <section className="userMembershipContainer">
        <UsersSelectionList
          userId={selectedUser?.id}
          setSelectedUser={setSelectedUser}
          tenantName={selectedTenant?.tenantName}
        />
        <GroupsTable
          userId={selectedUser?.id}
          tenantName={selectedTenant?.tenantName}
        />
      </section>
    </Stack>
  );
};

export default UserMembership;
