import React, { useEffect } from "react";
import Connections from "../../Connections";
import { myLocalStorage } from "../../../../components/StorageHelper";

const DeviceConnectionsDetails = ({ device }) => {
  useEffect(() => {
    myLocalStorage.setItem("activeComputer", device);
  }, []);
  return (
    <>
      <Connections />
    </>
  );
};

export default DeviceConnectionsDetails;
