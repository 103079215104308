import React, { useState } from "react";

import CategoriesTable from "./CategoriesTable";
import ResourcesTable from "./ResourcesTable";
import { Box } from "@mui/system";

const CategoryItem = ({ resourceType, profile, selectedTenant}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [resourcesAreLoading, setResourcesAreLoading] = useState(false);
  const [resourcesWereUpdated, setResourcesWereUpdated] = useState(false);

  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <CategoriesTable
        resourcesAreLoading={resourcesAreLoading}
        resourceType={resourceType}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        profile={profile}
        os={profile.os}
        selectedTenantName={selectedTenant?.tenantName}
        setResourcesWereUpdated={setResourcesWereUpdated}
      />
      <ResourcesTable
        setResourcesAreLoading={setResourcesAreLoading}
        resourcesAreLoading={resourcesAreLoading}
        resourceType={resourceType}
        profile={profile}
        os={profile.os}
        selectedCategory={selectedCategory}
        selectedTenantName={selectedTenant?.tenantName}
        resourcesWereUpdated={resourcesWereUpdated}
        setResourcesWereUpdated={setResourcesWereUpdated}
      />
    </Box>
  );
};

export default CategoryItem;
