import React, { useEffect, useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Autocomplete,
  Stack,
  TextField,
} from "@mui/material";
import useUserStore from "../../../services/userStore";
import SearchField from "./components/SearchField";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import CustomDatePicker from "./components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { myLocalStorage } from "../../../components/StorageHelper";
import { setLocalStorageTenant } from "../../../services/Helpers";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";

const DeviceDebugLogs = () => {
  const tenant = myLocalStorage.getItem("latestTenant");
  const userEmail = useUserStore((state) => state.user.email);

  const [debugLogs, setDebugLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [tenantsList, setTenantsList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const [devicesList, setDevicesList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const forbiddenSearchFields = ["bucket", "tenantName"];
  const { searchTerm, filteredData, handleSearch } = useGeneralSearch(
    debugLogs,
    null,
    forbiddenSearchFields,
  );

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: logs,
  } = useDateFilter(filteredData, "time");

  const fetchTenantsData = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/get-all-domains?email=${userEmail}`,
      );
      setTenantsList(response.data);
    } catch (error) {
      console.error(error, "set tenants lsit error");
    }
  };

  const fetchDevices = async (tenantName) => {
    try {
      const response = await axios.get(`${NGROK}/api/${tenantName}/computers`);
      setDevicesList(response.data);
    } catch (error) {
      console.error(error, "set devices lsit error");
    }
  };

  const fetchDebugLogs = async () => {
    try {
      const res = await axios.get(`${NGROK}/api/debug-logs`);
      setDebugLogs(res.data);
      setLoading(false);
    } catch (error) {
      console.error(error, "error fetchDebugLogs ");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDebugLogs();
  }, []);

  const pageIdle = () => {
    fetchDebugLogs();
    activate();
  };

  const { activate } = useIdleTimer({
    timeout: 1000 * 10,
    onIdle: pageIdle,
    onActive: () => {},
    onAction: () => {},
    debounce: 500,
  });

  useEffect(() => {
    fetchTenantsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  useEffect(() => {
    if (!selectedTenant && tenantsList?.length) {
      setSelectedTenant(tenantsList[0]);
      setLocalStorageTenant(tenantsList[0]);
    }
  }, [tenantsList, selectedTenant]);

  useEffect(() => {
    if (selectedTenant) fetchDevices(selectedTenant.tenantName);
  }, [selectedTenant]);

  const filterLogs = (logs, tenantName, deviceName = "") => {
    return logs?.filter((log) =>
      !deviceName
        ? log.tenantName === tenantName
        : log.tenantName === tenantName && log.computerName === deviceName,
    );
  };

  const deviceDebugLogs = selectedTenant
    ? filterLogs(logs, selectedTenant.tenantName, selectedDevice?.dNSHostName)
    : [];

  if (loading) return <CircularProgress />;

  return (
    <IdleTimerProvider>
      <Stack spacing={4}>
        <Stack spacing={4} direction={"row"}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={selectedTenant ? selectedTenant.tenantName : null}
            options={tenantsList.map((tenant) => tenant.tenantName)}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Tenant List" />
            )}
            onChange={(e, value) => {
              if (!value) {
                setSelectedTenant(null);
              }
              const tenant = tenantsList.find(
                (tenant) => tenant.tenantName === value,
              );
              if (tenant !== undefined) {
                setSelectedTenant(tenant);
              }
            }}
          />
          <Autocomplete
            disablePortal
            value={selectedDevice ? selectedDevice.dNSHostName : "All devices"}
            options={[
              "All devices",
              ...devicesList.map((device) => device.dNSHostName),
            ]}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Device List" />
            )}
            onChange={(e, value) => {
              if (!value) {
                setSelectedDevice(null);
              } else if (value === "All devices") {
                setSelectedDevice(null);
                return;
              }
              const device = devicesList.find(
                (device) => device.dNSHostName === value,
              );
              if (device !== undefined) {
                setSelectedDevice(device);
              }
            }}
          />
          <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        </Stack>
        <CustomDatePicker
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
        />

        {deviceDebugLogs.length ? (
          <TableContainer
            component={Paper}
            sx={{
              overflow: "hidden",
              display: "flex",
              height: "fit-content",
              width: "fit-content",
            }}
          >
            <Table
              size="small"
              aria-label="a dense table"
              sx={{
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
                width: "fit-content",
              }}
            >
              <TableHead
                sx={{
                  backgroundColor: "#233044",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Time
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Message
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceDebugLogs.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {log.time}
                    </TableCell>

                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {log.message}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : selectedTenant ? (
          <Typography fontSize={16} p={5}>
            There are no logs related to selected Tenant or Device
          </Typography>
        ) : (
          <Typography fontSize={16} p={5}>
            Select Tenant
          </Typography>
        )}
      </Stack>
    </IdleTimerProvider>
  );
};

export default DeviceDebugLogs;
