import React from "react";

import { Table, TableContainer, Paper } from "@mui/material";
import RequestsTableHead from "./RequestsTableHead";
import RequestsTableBody from "./RequestsTableBody";

const RequestsTable = ({
  setAdminNotifications,
  adminNotifications,
  fromdetails,
  computerName,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        border: 1,
        overflow: "hidden",
        display: "flex",
        height: "fit-content",
      }}
    >
      <Table
        sx={{
          borderCollapse: "collapse",
          borderStyle: "hidden",
          height: "fit-content",
          "& td, & th": {
            border: "1px solid #233044",
          },
        }}
      >
        <RequestsTableHead />

        <RequestsTableBody
          setAdminNotifications={setAdminNotifications}
          adminNotifications={adminNotifications}
          fromdetails={fromdetails}
          computerName={computerName}
        />
      </Table>
    </TableContainer>
  );
};

export default RequestsTable;
