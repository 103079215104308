import React from "react";

import { TextField } from "@mui/material";

const SearchField = ({ searchTerm, handleSearch, width }) => {
  const inputWidth = width || 250;
  return (
    <TextField
      value={searchTerm}
      onChange={(e) => handleSearch(e.target.value)}
      sx={{ width: inputWidth, height: "100%", backgroundColor: "white" }}
      label="Search..."
      id="search"
    />
  );
};

export default SearchField;
