import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import { resourceTypeListForCategoriesPage as resourceTypeList } from "../../../services/Helpers";

const ResouceTypeTable = ({ selectedResource, updateTheResource }) => {
  return (
    <>
      <TableContainer
        sx={{
          height: "fit-content",
        }}
        component={Paper}
      >
        <Table
          sx={{
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead sx={{ backgroundColor: "#233044" }}>
            <TableRow>
              <TableCell className="userName" sx={{ color: "white" }}>
                <Typography fontSize={17} fontWeight={600}>
                  Resource Type
                </Typography>{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceTypeList.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResource.id
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
                sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
              >
                <TableCell
                  component="td"
                  scope="row"
                  className="userRowName userName"
                  title={resource.name}
                  onClick={() => updateTheResource(resource)}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    className="userRowName userName"
                  >
                    <Stack direction={"row"} spacing={1.5}>
                      {resource.icons}
                      <span>{resource.name}</span>
                    </Stack>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResouceTypeTable;
