import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  List,
  ListItem,
  Chip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import { myLocalStorage } from "../../../../components/StorageHelper";

const AWSRequestResourceAccessForm = ({
  data,
  from,
  open,
  onClose,
  title,
  user,
}) => {
  const userData = useUserStore((state) => state.user);
  const [publishedPolicies, setPublishedPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [step, setStep] = useState(1);

  const [requestMessage, setRequestMessage] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [accessType, setAccessType] = useState("DYNAMIC");

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const getStringBeforeAtSymbol = (str) => {
    const atIndex = str.indexOf("@");
    return atIndex !== -1 ? str.substring(0, atIndex) : str;
  };

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    if (value?.length > 3 || value.includes("e") || value === 0) return;
    if (regex.test(value)) {
      setSelectedTime(value);
    } else if (!value) setSelectedTime("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const handleClose = () => {
    setRequestMessage("");
    setSelectedTime("");
    setSelectedPolicies([]); // Reset selected policies on close
    onClose();
  };

  const handleSubmit = async () => {
    const username = getStringBeforeAtSymbol(userData.email);

    const requestdata = {
      userId: userData?.userId,
      username: username,
      awsUsername: user?.userName,
      accessType: from,
      accessARN: data.groupArn,
      accessName: title,
      requestMessage,
      requestStatus: "PENDING",
      privilegeLevel: accessType,
      selectedTime: selectedTime,
      createdAt: new Date().toISOString(),
      tenantName: latestTenant,
      selectedPolicies: selectedPolicies,
      from: "resources",
    };

    try {
      await axios.post(`${NGROK}/api/aws-request-access`, requestdata);
      handleClose();
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };

  const fetchAllPublishedResources = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/aws/getAllPublishedPolicies?tenantName=${latestTenant}`,
      );
      if (response?.data) {
        setPublishedPolicies(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePolicySelect = (policy) => {
    if (selectedPolicies.includes(policy)) {
      setSelectedPolicies(selectedPolicies.filter((p) => p !== policy));
    } else {
      setSelectedPolicies([...selectedPolicies, policy]);
    }
  };

  useEffect(() => {
    fetchAllPublishedResources();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: "18px" }}>
        {step === 1 ? "Select Policies" : `Access request to ${title}`}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          {step === 1 ? (
            <List>
              {publishedPolicies.map((policy) => (
                <ListItem key={policy.policyArn}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPolicies.includes(policy)}
                        onChange={() => handlePolicySelect(policy)}
                      />
                    }
                    label={policy.policyName}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <>
              <Stack spacing={2} mt={3}>
                <Typography variant="h6">Selected Polices:</Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {selectedPolicies.map((policy, index) => (
                    <Chip
                      key={index}
                      label={policy.policyName}
                      variant="outlined"
                    />
                  ))}
                </Stack>
              </Stack>

              <FormControl component="fieldset">
                <Typography variant="h6">Access Type:</Typography>
                <RadioGroup
                  aria-label="access-type"
                  name="access-type"
                  value={accessType}
                  onChange={(e) => handleAccessType(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="PERMANENT"
                    control={<Radio />}
                    label="Permanent"
                  />
                  <FormControlLabel
                    value="DYNAMIC"
                    control={<Radio />}
                    label="Timed"
                  />
                </RadioGroup>
              </FormControl>

              <Typography variant="h6">Policy Name: {title}</Typography>

              <Stack spacing={2} mt={3}>
                <Typography variant="h6">Access justification:</Typography>
                <TextField
                  maxRows={5}
                  minRows={5}
                  inputProps={{ maxLength: 150 }}
                  multiline
                  value={requestMessage}
                  onChange={(e) => setRequestMessage(e.target.value)}
                />
              </Stack>

              {accessType === "DYNAMIC" && (
                <Stack spacing={2}>
                  <Typography variant="h6">Time:</Typography>
                  <TextField
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]+",
                    }}
                    onKeyPress={handleKeyPress}
                    value={selectedTime}
                    type="text"
                    fullWidth
                    size="small"
                    label={"Enter time in minutes"}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {step === 1 ? (
          <Button
            onClick={() => setStep(2)}
            color="primary"
            disabled={selectedPolicies.length === 0}
          >
            Next
          </Button>
        ) : (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AWSRequestResourceAccessForm;
