import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Box,
  MenuItem,
} from "@mui/material";

const TrustLevelSelect = ({ profile, category, updateCategory }) => {

  return (
    <Box
      key={`${profile.profileId || "empty"}`}
      display={"flex"}
      justifyContent={"space-between"}
      flexDirection={"column"}
      alignItems={"center"}
      height={"80px"}
    >
      <Typography variant={"body1"}>
        {profile.name || "Not configured"}
      </Typography>
      <FormControl sx={{ minWidth: 150 }}>
        <InputLabel htmlFor="demo-simple-select">Trust level</InputLabel>
        <Select
          name={profile.name}
          value={profile.trustLevel || "NOT_CONFIGURED"}
          onChange={(e) => {
            updateCategory(category, profile, e.target.value);
          }}
          label="Trust level"
          id="demo-simple-select"
          sx={{ height: 45 }}
        >
          {!profile.trustLevel ? (
            <MenuItem value={"NOT_CONFIGURED"}>NOT CONFIGURED</MenuItem>
          ) : null}
          <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
          <MenuItem value={"DISABLED"}>DENIED</MenuItem>
          <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default TrustLevelSelect;
