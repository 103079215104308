import React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { bgBlur } from "./HFConfig";

const Footer = ({ theme }) => {
  return (
    <Box
      sx={{
        py: 2,
        mt: "auto",
        ...bgBlur({ color: theme.palette.background.default }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ flex: 0.2 }} />
      <Typography variant="body2" color="inherit">
        © 2024 Whiteswan Security. All rights reserved.
      </Typography>
      <Stack spacing={2} sx={{ display: "flex", mr: 5 }} direction="row">
        <Link
          href="https://app.termly.io/document/terms-of-service/d8371bb3-6629-4b1e-95c5-3cf56e89c51c"
          variant="body2"
          color="inherit"
          target="_blank"
          rel="noopener"
          style={{
            display: "flex",
            alignItems: "center",
            color: "black",
            textDecoration: "none",
          }}
        >
          Terms and Conditions
        </Link>
        <Link
          href="https://www.whiteswansecurity.com/about/"
          variant="body2"
          color="inherit"
          target="_blank"
          rel="noopener"
          style={{
            display: "flex",
            alignItems: "center",
            color: "black",
            textDecoration: "none",
          }}
        >
          About
        </Link>
        <Link
          href="https://www.whiteswansecurity.com/contact/"
          variant="body2"
          color="inherit"
          target="_blank"
          rel="noopener"
          style={{
            display: "flex",
            alignItems: "center",
            color: "black",
            textDecoration: "none",
          }}
        >
          Contact
        </Link>
      </Stack>
    </Box>
  );
};

export default Footer;
