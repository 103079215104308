import React from "react";
import styled from "styled-components";

import { Badge, Grid, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import UserService from "../../services/UserService";
import { myLocalStorage } from "../StorageHelper";
import { decodeKeycloakToken } from "../../utils/tokenHelpers";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const storedToken = myLocalStorage.getItem("access_token");
  const token = UserService?.getToken() || storedToken || null;
  const tokenParsed = decodeKeycloakToken(token) || null;

  return (
    <Footer {...rest}>
      <Grid container>
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              background: "#ffffff",
            }}
          >
            <PersonIcon />
          </FooterBadge>
        </Grid>
        <Grid item>
          <FooterText variant="body1">
            {!tokenParsed ? "No name" : tokenParsed.name}
          </FooterText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
