import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  ButtonGroup,
} from "@mui/material";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const MatchedDevicesTable = ({ unitId, setOuDevices, ouDevices }) => {
  useEffect(() => {
    const fetchOuDevices = async () => {
      try {
        const response = await axios.get(
          `${NGROK}/api/organization-units/${unitId}/computers`
        );

        setOuDevices(response.data);
      } catch (error) {
        setOuDevices([]);
        console.error(error);
      }
    };

    if (unitId) fetchOuDevices();
  }, [setOuDevices, unitId]);

  const filteredDevices = ouDevices?.filter(
    (device) => device.hasAgent
  );
  return (
    <Box>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        sx={{ display: "flex", justifyContent: "center", padding: 2 }}
      >
        {/* <Button onClick={removeAllFromExclusions}>Include all</Button>
        <Button onClick={addAllToExclusions}>Remove all</Button> */}
      </ButtonGroup>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 40 }} align="left">
              Sl No
            </TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">OS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredDevices?.map((device, index) => (
            <TableRow
              key={`${device.name}  ${index}`}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="td" scope="row" align="left">
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  wordWrap: "break-word",
                  maxWidth: "260px",
                }}
                align="left"
              >
                {device.Name}
              </TableCell>
              <TableCell
                sx={{
                  wordWrap: "break-word",
                  maxWidth: "260px",
                }}
              >
                {device.OperatingSystem}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MatchedDevicesTable;
