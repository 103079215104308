import React from "react";
import axios from "axios";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { NGROK } from "../../../../APIs";

const OperatingModeSelection = ({ setSelectedTenant, selectedTenant }) => {
    
  const updateTenantOperationMode = async (event, tenant) => {
    const newMode = event.target.value;
    const updatedTenant = { ...tenant, mode: newMode };

    setSelectedTenant(updatedTenant);

    try {
      await axios.put(`${NGROK}/api/${tenant.tenantName}/operation-mode`, {
        mode: newMode,
      });
    } catch (error) {
      console.error("Error during request to server");
    }
  };
  return (
    <Stack direction={"row"} spacing={4}>
      <FormControl sx={{ display: "flex", alignItems: "center" }}>
        <InputLabel id="demo-simple-select-label">Operation Mode</InputLabel>
        <Select
          sx={{ minWidth: "100px", backgroundColor: "white" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Operation Mode"
          value={selectedTenant.mode ? selectedTenant.mode : " "}
          size="medium"
          onChange={(event) => updateTenantOperationMode(event, selectedTenant)}
        >
          <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
          <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
          <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
          );
        </Select>
      </FormControl>
    </Stack>
  );
};

export default OperatingModeSelection;
