import React from "react";
import Default from "../../../dashboards/Default";

const DeviceOverViewDetails = ({ computerName, selectedTenant }) => {
  return (
    <>
      <Default
        fromDetails={true}
        computerName={computerName}
        selectedTenant={selectedTenant}
      />
    </>
  );
};

export default DeviceOverViewDetails;
