import React from "react";
import LOLBin from "../../../dashboards/LOLBin";

const DeviceLolBinDetails = ({ computerName }) => {
  return (
    <>
      <LOLBin fromDetails={true} computerName={computerName} />
    </>
  );
};

export default DeviceLolBinDetails;
