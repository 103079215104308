import React from "react";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
} from "@mui/material";
import "../../pages.scss";
import MyHeaderTableCell from "../../Components/MyHeaderTableCell";

const UsersComputersSelection = ({ selectedItem, setSelectedItem }) => {
    
  const itemList = ["Users", "Computers"];

  return (
    <TableContainer
      style={{ height: "fit-content", width: 200 }}
      component={Paper}
    >
      <Table
        sx={{
          width: "100%",
          border: "1px solid #233044",
        }}
        size="large"
      >
        <TableHead>
          <TableRow>
            <MyHeaderTableCell
              sx={{ fontSize: 18 }}
              align={"center"}
              className={"userName"}
            >
              Member Type
            </MyHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList.map((item) => (
            <TableRow
              className={
                item === selectedItem ? "userRow activeUserRow" : "userRow"
              }
              key={item}
              sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
              onClick={() => {
                setSelectedItem(item);
              }}
            >
              <TableCell
                sx={{ fontSize: 18 }}
                component="td"
                scope="row"
                className="userRowName userName"
                title={item}
              >
                {item}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersComputersSelection;
