import React, { useEffect } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

const ProfileSelection = ({
  profileList,
  selectedOS,
  selectedProfile,
  setSelectedProfile,
}) => {
    const profilesFilteredByOs = profileList?.filter((profile) =>
      selectedOS.toUpperCase() !== "MACOS"
        ? profile.os.toUpperCase() === selectedOS.toUpperCase()
        : profile.os.toUpperCase() === "MAC"
    );
  
  const defaultProfile = profilesFilteredByOs?.find(
    (profile) => profile.type === "ORIGINAL"
  );

  const handleProfileSelection = (profileName) => {
    if (!profileName) setSelectedProfile(null);
    else {
      const profile = profilesFilteredByOs?.find(
        (profile) => profile.name === profileName
      );
      setSelectedProfile(profile);
    }
  };

  useEffect(() => {
    if (!selectedProfile) setSelectedProfile(defaultProfile);
    else if (
      selectedProfile &&
      selectedProfile.os.toLowerCase() !== selectedOS.toLowerCase()
    ) {
      setSelectedProfile(defaultProfile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile, selectedOS]);

  const selectValue =
    selectedProfile &&
    selectedProfile.os.toLowerCase() === selectedOS.toLowerCase()
      ? selectedProfile?.name
      : defaultProfile?.name;

  return (
    <Box>
      {profilesFilteredByOs?.length > 1 ? (
        <Autocomplete
          sx={{ paddingTop: 2 }}
          fullWidth
          disablePortal
          id="combo-box-demo"
          value={selectValue}
          options={profilesFilteredByOs?.map((profile) => profile.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Typography variant="subtitle1" fontSize={17}>
                  {" "}
                  Select profile{" "}
                </Typography>
              }
            />
          )}
          onChange={(e, value) => handleProfileSelection(value)}
        />
      ) : (
        <Typography align="center" fontSize={14}>
          No profiles for selected OS
        </Typography>
      )}
    </Box>
  );
};

export default ProfileSelection;
