import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import PasswordFormControl from "./PasswordFormControl";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const CreateCredentialDialog = ({
  credential,
  tenantName,
  osValue,
  setCredentialsList,
  credentialLables,
}) => {
  const [open, setOpen] = useState(false);

  const [label, setLabel] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [labelErrorMessage, setLabelErrorMessage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setLabel("");
    setUsername("");
    setPassword("");
    setLabelErrorMessage("");

    setOpen(false);
  };

  const handleLabelInput = (value) => {
    setLabelErrorMessage("");

    if (value.includes("_")) return;
    else setLabel(value);
  };

  const postNewCredential = async () => {
    try {
      const response = await axios.post(`${NGROK}/api/credentials`, {
        label,
        username,
        password,
        retypePassword: password,
        tenantName,
        os: osValue,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    const isCredentialUniq = !credentialLables?.includes(label.toLowerCase());

    if (!credential && !isCredentialUniq) {
      setLabelErrorMessage("Credential with this label already exists.");
      return;
    }

    const newCred = await postNewCredential();
    const updatedCred = {
      label: `${tenantName}_${osValue}_${label}`,
      username: newCred?.username,
      password: newCred?.password,
    };

    if (!credential) setCredentialsList((prev) => [...prev, updatedCred]);
    else {
      setCredentialsList((prev) => {
        return prev.map((cred) =>
          cred.label.toLowerCase() === updatedCred.label.toLowerCase()
            ? updatedCred
            : cred,
        );
      });
    }

    handleClose();
  };

  useEffect(() => {
    if (credential) {
      const credLabel = credential.label.split("_")[2].toLowerCase();

      setLabel(credLabel);
      setUsername(credential.username);
      setPassword(credential.password);
    }
  }, [credential]);

  const isSubmitBtnEnabled = label && password && username;

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {credential ? "Edit" : "Add credentials"}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>
          {credential ? "Edit credential" : "Add credentials"}
        </DialogTitle>
        <DialogContent>
          <Stack direction={"column"} spacing={5} sx={{ mt: 2 }}>
            <TextField
              disabled={!!credential}
              label={"Credential label"}
              autoFocus
              margin="dense"
              type="text"
              inputProps={{ maxLength: 250 }}
              value={label}
              onChange={(e) => handleLabelInput(e.target.value)}
            />
            {labelErrorMessage ? (
              <Alert sx={{ marginTop: 1 }} severity="error">
                {labelErrorMessage}
              </Alert>
            ) : null}
            <TextField
              label={"Username"}
              inputProps={{ maxLength: 250 }}
              type="text"
              margin="dense"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <PasswordFormControl
              password={password}
              setPassword={setPassword}
              label={"Password"}
            />
            {/* 
            <PasswordFormControl
              password={password}
              setPassword={setPassword}
              label={"Confirm password"}
            /> */}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            disabled={!isSubmitBtnEnabled}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateCredentialDialog;
