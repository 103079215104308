import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

// import { spacing } from "@mui/system";
import { MoreVertical } from "react-feather";
import {
  CardContent,
  CardHeader,
  IconButton,
  Card,
  CircularProgress,
} from "@mui/material";
// const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;

const ResoruceType = ({ data }) => {
  const [count, setCount] = useState([]);
  const [label, setLabel] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      const typeList = data.map((type) => type.resourceType);
      const counts = typeList.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});

      let series = [];
      let seriesLabel = [];

      for (const [key, value] of Object.entries(counts)) {
        series.push(value);
        seriesLabel.push(key);
      }
      setCount(series);
      setLabel(seriesLabel);
      setLoading(false);
    }
  }, [data]);

  const chartData = {
    series: count,
    options: {
      chart: {
        type: "pie",
      },
      labels: label,
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return value + " times";
          },
        },
      },
    },
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Resource type"
          action={
            <IconButton aria-label="settings" size="large">
              <MoreVertical />
            </IconButton>
          }
        />
        <CardContent>
          <ChartWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              data !== null && (
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="pie"
                  height="100%"
                />
              )
            )}
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default ResoruceType;
