import React, { useEffect, useState } from "react";
import axios from "axios";

import MyHeaderTableCell from "../../Components/MyHeaderTableCell";
import styled from "styled-components";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import UserItem from "./UserItem";
import useGeneralSearch from "../../../../hooks/useGeneralSearch";
import SearchField from "../../logs/components/SearchField";
import { Box } from "@mui/system";

const MyTableCell = styled(TableCell)`
  font-size: 18px;
`;

const UsersTable = ({ setSelectedUser, tenantName, unitId }) => {
  const [usersList, setUsersList] = useState([]);

  const {
    searchTerm,
    filteredData: users,
    handleSearch,
  } = useGeneralSearch(usersList);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${NGROK}/api/organization-units/${unitId}/users`,
        );
        setUsersList(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (unitId) fetchUsers();
  }, [setUsersList, tenantName, unitId]);

  return (
    <Stack spacing={2}>
      <Box textAlign={"right"}>
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
      </Box>

      <Typography variant="h6" color="initial" fontWeight={600} align="center">
        Total Count: {users?.length || "0"}
      </Typography>
      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table
          sx={{
            width: "100%",
            border: "1px solid #233044",
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <MyHeaderTableCell
                sx={{ fontSize: 18 }}
                align={"center"}
                className={"userName"}
              >
                Users
              </MyHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <TableRow
                className="groupRow"
                key={`${user.id}${user.DistinguishedName}`}
                sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                onClick={() => {
                  setSelectedUser(user);
                }}
              >
                <MyTableCell
                  component="td"
                  scope="row"
                  className="userRowName userName"
                >
                  <UserItem user={user} />
                </MyTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default UsersTable;
