import axios from "axios";
import { NGROK } from "../../../APIs";

export const fetchEmails = async (tenantName) => {
  try {
    const response = await axios.get(
      `${NGROK}/api/emails/to-address?tenantName=${tenantName}`
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const checkIfNotificationsEnabled = async (tenantName) => {
  try {
    const response = await axios.get(
      `${NGROK}/api/emails/to-address/enabled?tenantName=${tenantName}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const postEmails = async (postBody) => {
  const response = await axios.post(`${NGROK}/api/emails/to-address`, postBody);
  return response?.data;
};
