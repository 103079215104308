import React from "react";
import DevieSpecificLogs from "../../logs/DevieSpecificLogs";

const DeviceAuditLogsDetails = ({ device, selectedTenant }) => {
  return (
    <>
      <DevieSpecificLogs device={device} selectedTenantData={selectedTenant} />
    </>
  );
};

export default DeviceAuditLogsDetails;
