import React from "react";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const CustomDatePicker = ({dateRange, handleChangeDateRange}) => {
    
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="Start date"
        endText="End date"
        value={dateRange}
        onChange={handleChangeDateRange}
        renderInput={(startProps, endProps) => (
          <Stack direction={"row"} spacing={3}>
            <TextField {...startProps} />
            <TextField {...endProps} />
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
