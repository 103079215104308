import React, { useEffect, useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Table } from "@mui/material";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import useUserStore from "../../../services/userStore";
import CreateUrlDialog from "./components/CreateUrlDialog";
import UrlControlRow from "./components/UrlControlRow";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import { getFilteredEndpointsResources } from "../../../services/Helpers";

const URLcontrol = ({ tenantName, selectedUser, computer }) => {
  const userRole = useUserStore((state) => state.user.role);

  const [filterResourceValue, setFilterResourceValue] = useState(
    userRole !== "TENANT_USER" ? "ALL" : "DENIED",
  );
  const [searchValue] = useState("");
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [urlControlList, setUrlControlList] = useState([]);
  const [urlControls, setUrlControls] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUrlControlData = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${tenantName}/computers/${computer.id}/computer-users/${selectedUser.id}/url-control`,
      );
      const sortedUrls = response.data.content?.sort(
        (a, b) => a.urlId - b.urlId,
      );
      setUrlControlList(sortedUrls);
    } catch (error) {
      console.error(error, "error during fetching urls");
    } finally {
      setLoading(false);
    }
  };

  const { vertical, horizontal, open } = snackbarSettings;

  const getUserUrls = () => {
    const timedUrls = getFilteredEndpointsResources(
      searchValue,
      "TIMED",
      urlControlList,
    );
    const deniedUrls = getFilteredEndpointsResources(
      searchValue,
      "DENIED",
      urlControlList,
    );
    return [...timedUrls, ...deniedUrls];
  };

  const calculateUrlControls = () => {
    const controls =
      userRole !== "TENANT_USER"
        ? getFilteredEndpointsResources(
            searchValue,
            filterResourceValue,
            urlControlList,
          )
        : getUserUrls();
    setUrlControls(controls);
  };

  const handleFilterResourceValue = (e) => {
    setFilterResourceValue(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    fetchUrlControlData();

    const interval = setInterval(() => {
      fetchUrlControlData();
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    calculateUrlControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlControlList, filterResourceValue]);

  if (loading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={"fit-content"}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() =>
          setSnackbarSettings({ ...snackbarSettings, open: false })
        }
        message="You can't make this resource inherited"
        key={vertical + horizontal}
      >
        <MuiAlert
          sx={{ fontSize: "18px", fontWeight: "500" }}
          severity="warning"
        >
          You can't make this resource inherited
        </MuiAlert>
      </Snackbar>
      <Stack spacing={2}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{ padding: "15px" }}
        >
          {userRole !== "TENANT_USER" ? (
            <RadioAuthFilter
              userRole={userRole}
              filterResourceValue={filterResourceValue}
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
            />
          ) : null}
          {userRole !== "TENANT_USER" ? (
            <CreateUrlDialog
              fetchUrlControlData={fetchUrlControlData}
              tenantName={tenantName}
              computerId={computer.id}
            />
          ) : null}
        </Box>
        <Typography
          variant="h6"
          color="initial"
          fontWeight={600}
          align="center"
        >
          Total Count: {urlControls.length || "0"}
        </Typography>

        <TableContainer component={Paper} sx={{ height: "fit-content" }}>
          <Table
            sx={{
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <MyHeaderTableCell align={"center"} className={"userName"}>
                  URL
                </MyHeaderTableCell>
                <MyHeaderTableCell align={"center"} className={"userName"}>
                  Privilege Level
                </MyHeaderTableCell>
                {userRole !== "TENANT_USER" ? (
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Management
                  </MyHeaderTableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {urlControls?.map((urlControl) => (
                <React.Fragment key={urlControl.name}>
                  <UrlControlRow
                    selectedUser={selectedUser}
                    fetchUrlControlData={fetchUrlControlData}
                    urlControl={urlControl}
                    tenantName={tenantName}
                    computer={computer}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
};

export default URLcontrol;
