import React from "react";
import { Box } from "@mui/system";
import { MainLogo } from "../../../../components/MainLogo";

const FormLogo = () => {

  return (
    <Box
      height={70}
      width={"100%"}
      sx={{
        backgroundColor: "#233141",
        borderRadius: 1,
        textAlign: "center",
      }}
    >
      <MainLogo />
    </Box>
  );
};

export default FormLogo;
