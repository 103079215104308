import React, { useState, useEffect } from "react";
import axios from "axios";

import styled from "styled-components";
import { Outlet, useLocation } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import {
  userNavsItems,
  adminNavsItems,
  sysAdminNavsItems,
} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";

import useUserStore from "../services/userStore";
import { NGROK } from "../APIs";
import { useCallback } from "react";
import BreadcrumbsNavProfiles from "../components/BreadcrumbsNavProfiles";
import BreadcrumbsNavEndpoints from "../components/BreadcrumbsNavEndpoints";
import useNotificationsStore from "../services/notificationStore";
import IdleTimer from "../components/IdleTimer/IdleTimer";
import AWSBreadCrumpUsers from "../pages/pages/AWS/components/AWSBreadCrumpUsers";
import { myLocalStorage } from "../components/StorageHelper";
import UrlFetchManager from "../services/UrlFetchManager";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userAccessMenu, setUserAccessMenu] = useState(null);

  const location = useLocation();
  const {
    setAdminNotifications,
    setUserNotifications,
    setAdminAWSNotifications,
  } = useNotificationsStore();

  const userData = useUserStore((state) => state.user);
  const activeComputer = useUserStore((state) => state.activeComputer);

  const latestComputer =
    activeComputer || myLocalStorage.getItem("activeComputer");
  const lastSession = useUserStore((state) => state.lastSession);

  const latestTenant =
    lastSession.latestTenant ||
    myLocalStorage.getItem("latestTenant")?.tenantName;

  let userAccess = userData.role;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (userAccess === "TENANT_ADMIN") {
      setUserAccessMenu(adminNavsItems);
    }
    if (userAccess === "SYS_ADMIN") {
      setUserAccessMenu(sysAdminNavsItems);
    }
    if (userAccess === "TENANT_USER") {
      setUserAccessMenu(userNavsItems);
    }
  }, [userAccess]);

  const fetchNotifications = useCallback(async () => {
    try {
      if (userAccess === "TENANT_ADMIN" || userAccess === "SYS_ADMIN") {
        const response = await UrlFetchManager(
          `${NGROK}/api/access/admin-notifications`,
        );
        const adminresponse = await UrlFetchManager(
          `${NGROK}/api/aws-request-access?tenantName=${latestTenant}`,
        );

        if (location?.pathname === "/endpoints/deviceDetails") {
          let computerName = latestComputer?.dNSHostName;

          let filteredData =
            response?.data && response?.data.length > 0
              ? response?.data.filter(
                  (item) =>
                    item?.computerName?.toLowerCase() ===
                    computerName?.toLowerCase(),
                )
              : [];
          setAdminNotifications(filteredData);
        } else {
          setAdminNotifications(response.data);
        }
        setAdminAWSNotifications(adminresponse.data);
      } else {
        const response = await axios.get(
          `${NGROK}/api/access/user-notifications-by-email?email=${userData.email}`,
        );
        setUserNotifications(response.data);
      }
    } catch (error) {
      console.log(error, "error fetchNotifications");
    }
  }, [
    setAdminNotifications,
    setUserNotifications,
    userAccess,
    userData,
    latestComputer,
  ]);

  useEffect(() => {
    if (userData.email) fetchNotifications();
    const interval = setInterval(() => {
      fetchNotifications();
    }, 10000);
    return () => clearInterval(interval);
  }, [fetchNotifications, userAccess, latestComputer, userData]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={userAccessMenu}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={userAccessMenu}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 8 : 4}>
          {location.pathname.startsWith("/endpoints") ? (
            <Box p={4}>
              <BreadcrumbsNavEndpoints />
            </Box>
          ) : location.pathname.startsWith("/policyProfiles") ? (
            <Box p={4}>
              <BreadcrumbsNavProfiles />
            </Box>
          ) : location.pathname.startsWith("/aws") ? (
            <Box p={4}>
              <AWSBreadCrumpUsers />
            </Box>
          ) : null}
          <IdleTimer />

          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
