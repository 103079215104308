import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";

const AWSUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { role, userId, email } = useUserStore((state) => state.user);
  const [configureAccountModalOpen, setConfigureAccountModalOpen] =
    useState(false);
  const [awsUsername, setAwsUsername] = useState("");
  const [accountId, setAccountId] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isAWSUserConfigured, setIsAWSUserConfigured] = useState(false);
  const [isAWSConfigurationLoading, setIsAWSConfigurationLoading] =
    useState(false);
  const navigate = useNavigate();

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const getSpecificUsers = async (name) => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIAMUsers?tenantName=${latestTenant}&name=${name}`,
    );
    return response.data;
  };

  const getAllUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getAllIAMUsers?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/list-iam-groups?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const getAllRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/list-iam-roles?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const combinedPermissionandGetRoles = (users, roles) => {
    return users.map((user) => {
      const items = consolidatepermissions(user);
      const matchingRoles = [];

      roles.forEach((role) => {
        role?.inlinePolicies?.forEach((policy) => {
          if (items.includes(policy.policyArn)) {
            matchingRoles.push({
              roleName: role.roleName,
              roleId: role.roleId,
              roleArn: role.roleArn,
            });
          }
        });

        role?.attachedPolicies?.forEach((policy) => {
          if (items.includes(policy.policyArn)) {
            matchingRoles.push({
              roleName: role.roleName,
              roleId: role.roleId,
              roleArn: role.roleArn,
            });
          }
        });
      });

      user.roles = matchingRoles;

      return user;
    });
  };

  const fetchData = async () => {
    try {
      const [users, groups, roles] = await Promise.all([
        getAllUsers(),
        getAllGroups(),
        getAllRoles(),
      ]);

      let userWithRoles = combinedPermissionandGetRoles(users, roles);
      const combineData = {
        Users: userWithRoles,
        Groups: groups,
        Roles: roles,
      };
      setUsersData(combineData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const fetchAWSUserConfiguration = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getAWSUserConfig?tenantName=${latestTenant}&tenantEmail=${email}`,
    );
    if (response?.data) {
      let { awsAccountARN, awsUserName } = response?.data;
      setIsAWSUserConfigured(awsAccountARN != null ? false : true);
      setConfigureAccountModalOpen(awsAccountARN != null ? false : true);
      if (awsAccountARN && awsUserName) {
        setIsAWSConfigurationLoading(true);
        fetchAWSConfigureDetails(awsUserName);
      }
    } else {
      setIsAWSUserConfigured(false);
      setConfigureAccountModalOpen(true);
    }
  };
  const fetchAWSConfigureDetails = async (userName) => {
    try {
      const [users, groups, roles] = await Promise.all([
        getSpecificUsers(userName),
        getAllGroups(),
        getAllRoles(),
      ]);

      let userWithRoles = combinedPermissionandGetRoles(users, roles);
      const specificUserdata = userWithRoles[0];
      setIsAWSConfigurationLoading(false);
      navigate("/aws/users/userDetails", {
        state: { user: specificUserdata, groups },
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const validateAndConfigureTheAwsUser = async () => {
    try {
      const response = await axios.post(
        `${NGROK}/api/aws/addAWSUserConfig?tenantName=${latestTenant}&tenantEmail=${email}&awsUserName=${awsUsername}&awsAccountARN=${accountId}`,
      );
      if (response?.data) {
        fetchAWSUserConfiguration();
      }
    } catch (ex) {
      console.error(ex);
    }

    setConfigureAccountModalOpen(false);
  };

  useEffect(() => {
    if (role !== "TENANT_USER") {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      fetchAWSUserConfiguration();
    }
  }, [latestTenant]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "awsUsername") {
      setAwsUsername(value);
    } else if (name === "accountId") {
      setAccountId(value);
    }

    if (awsUsername && accountId) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const MAX_ITEMS_DISPLAYED = 2;

  const renderItemsWithMoreLink = (items, maxItems, user, groups) => {
    if (items.length <= maxItems) {
      return items.join(", ");
    } else {
      const displayedItems = items.slice(0, maxItems).join(", ");
      const remainingCount = items.length - maxItems;
      return (
        <>
          {displayedItems},{" "}
          <Link to={`/aws/users/userDetails`} state={{ user, groups }}>
            {remainingCount} more
          </Link>
        </>
      );
    }
  };

  const renderItemsWithMoreLinkAndConsolidating = (
    permissions,
    maxItems,
    user,
    groups,
  ) => {
    const items = consolidatepermissions(permissions);

    if (items.length <= maxItems) {
      return items.join(", ");
    } else {
      const displayedItems = items.slice(0, maxItems).join(", ");
      const remainingCount = items.length - maxItems;
      return (
        <>
          {displayedItems},{" "}
          <Link to={`/aws/users/userDetails`} state={{ user, groups }}>
            {remainingCount} more
          </Link>
        </>
      );
    }
  };

  const consolidatepermissions = (permissions) => {
    const consolidations = permissions
      ? [
          ...permissions.attachedUserPolicies,
          ...permissions.inlineUserPolicies,
          ...permissions.groupPolicies,
          ...permissions.groupInlinePolicies,
        ]
      : [];
    return consolidations;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = usersData
    ? usersData.Users.filter((user) =>
        user.userName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  if (loading && role !== "TENANT_USER") {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {role != "TENANT_USER" ? (
        <Stack spacing={5}>
          <Box display="flex" justifyContent="flex-end">
            <Stack direction={"row"} spacing={2}>
              <TextField
                placeholder="Search users..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* <Button variant="outlined" color="primary">
              Create User
            </Button> */}
            </Stack>
          </Box>
          {!usersData ? (
            <Typography
              variant="body2"
              color="textSecondary"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              No datas available
            </Typography>
          ) : filteredUsers.length === 0 ? (
            <Typography
              variant="body2"
              color="textSecondary"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              No Users available
            </Typography>
          ) : (
            <TableContainer style={{ height: "fit-content" }} component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  maxWidth: "100%",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "16px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">User Name</TableCell>
                    <TableCell align="center">Groups</TableCell>
                    <TableCell align="center">Roles</TableCell>
                    <TableCell align="center">Permissions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user.userName}>
                      <TableCell align="center">
                        <Link
                          to={`/aws/users/userDetails`}
                          state={{ user, groups: usersData.Groups }}
                        >
                          {user.userName}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {renderItemsWithMoreLink(
                          user.groups.map((group) => group.groupName),
                          MAX_ITEMS_DISPLAYED,
                          user,
                          usersData.Groups,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {renderItemsWithMoreLink(
                          user.roles.map((role) => role.roleName),
                          MAX_ITEMS_DISPLAYED,
                          user,
                          usersData.Groups,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {renderItemsWithMoreLinkAndConsolidating(
                          user,
                          MAX_ITEMS_DISPLAYED,
                          user,
                          usersData.Groups,
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      ) : (
        <>
          {isAWSUserConfigured ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setConfigureAccountModalOpen(true)}
              >
                Configure aws account
              </Button>
              <Dialog
                open={configureAccountModalOpen}
                onClose={() => {
                  setConfigureAccountModalOpen(false);
                }}
              >
                <DialogTitle>AWS User Configuration</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="AWS Username"
                    name="awsUsername"
                    fullWidth
                    variant="outlined"
                    value={awsUsername}
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="dense"
                    label="Account ARN"
                    name="accountId"
                    fullWidth
                    variant="outlined"
                    value={accountId}
                    onChange={handleInputChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setConfigureAccountModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!isButtonEnabled}
                    onClick={() => {
                      validateAndConfigureTheAwsUser();
                    }}
                  >
                    Validate
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          ) : isAWSConfigurationLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default AWSUsers;
