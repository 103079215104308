import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import { myLocalStorage } from "../../../../components/StorageHelper";

const AWSRequestAccessForm = ({ data, from, open, onClose, title, user }) => {
  console.log(data, from, title, user);
  const userData = useUserStore((state) => state.user);

  const [requestMessage, setRequestMessage] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [accessType, setAccessType] = useState("DYNAMIC");

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const getStringBeforeAtSymbol = (str) => {
    const atIndex = str.indexOf("@");
    return atIndex !== -1 ? str.substring(0, atIndex) : str;
  };

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    if (value?.length > 3 || value.includes("e") || value === 0) return;

    if (regex.test(value)) {
      setSelectedTime(value);
    } else if (!value) setSelectedTime("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const handleClose = () => {
    setRequestMessage("");
    setSelectedTime("");
    onClose();
  };

  const handleSubmit = async () => {
    const username = getStringBeforeAtSymbol(userData.email);

    // Debugging: Log all relevant data before making the API call
    console.log("Submitting request with data:", {
      userId: userData?.userId,
      username: username,
      awsUsername: user?.userName,
      accessType: from,
      accessARN: data.groupArn,
      accessName: title,
      requestMessage,
      requestStatus: "PENDING",
      privilegeLevel: accessType,
      selectedTime: selectedTime,
      createdAt: new Date().toISOString(),
      tenantName: latestTenant,
    });

    const requestdata = {
      userId: userData?.userId,
      username: username,
      awsUsername: user?.userName,
      accessType: from,
      accessARN: data.groupArn,
      accessName: title,
      requestMessage,
      requestStatus: "PENDING",
      privilegeLevel: accessType,
      selectedTime: selectedTime,
      createdAt: new Date().toISOString(),
      tenantName: latestTenant,
    };

    try {
      await axios.post(`${NGROK}/api/aws-request-access`, requestdata);
      handleClose();
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: "18px" }}>
        Access request to {title}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <FormControl component="fieldset">
            <Typography variant="h6">Access Type:</Typography>
            <RadioGroup
              aria-label="access-type"
              name="access-type"
              value={accessType}
              onChange={(e) => handleAccessType(e.target.value)}
              row
            >
              <FormControlLabel
                value="PERMANENT"
                control={<Radio />}
                label="Permanent"
              />
              <FormControlLabel
                value="DYNAMIC"
                control={<Radio />}
                label="Timed"
              />
            </RadioGroup>
          </FormControl>

          <Typography variant="h6">Group Name: {title}</Typography>

          <Stack spacing={2} mt={3}>
            <Typography variant="h6">Access justification:</Typography>
            <TextField
              maxRows={5}
              minRows={5}
              inputProps={{ maxLength: 150 }}
              multiline
              value={requestMessage}
              onChange={(e) => setRequestMessage(e.target.value)}
            />
          </Stack>

          <Stack spacing={2}>
            <Typography variant="h6">Time:</Typography>
            <TextField
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]+",
              }}
              onKeyPress={handleKeyPress}
              value={selectedTime}
              type="text"
              fullWidth
              size="small"
              label={"Enter time in minutes"}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AWSRequestAccessForm;
