import React from "react";

import { TextField } from "@mui/material";

const UserSearch = ({ searchTerm, handleSearch }) => {
    
  return (
    <TextField
      value={searchTerm}
      onChange={(e) => handleSearch(e.target.value)}
      sx={{ width: 250 }}
      label="Search..."
      id="search"
    />
  );
};

export default UserSearch;
