import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";

const CreateUrlDialog = ({ tenantName, computerId, fetchUrlControlData }) => {
  const userEmail = useUserStore((state) => state.user.email);

  const [open, setOpen] = useState(false);
  const [urlName, setUrlName] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUrlName("");
    setOpen(false);
  };

  const createNewUrl = async () => {
    try {
      await axios.post(`${NGROK}/api/kafka/ui/url-control`, {
        tenant_name: tenantName,
        computer_id: computerId,
        data: [
          {
            name: urlName,
            Ips: ["142.250.196.14"],
            reputation_score: 85,
            email: userEmail,
          },
        ],
      });
      fetchUrlControlData();
    } catch (error) {
      console.error(error, "error during posting new url");
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button variant={"outlined"} onClick={handleClickOpen} size="large">
        Add a new URL
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Create URL control</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 4 }}>
            Type url name
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Url control name"
            type="text"
            fullWidth
            variant="outlined"
            name="UrlControlName"
            value={urlName}
            onChange={(e) => setUrlName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={createNewUrl}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUrlDialog;
