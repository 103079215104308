import axios from "axios";
import { NGROK } from "../../../APIs";

export const getComputers = async (tenantName) => {
  try {
    const response = await axios.get(`${NGROK}/api/${tenantName}/computers`);
    if (response.data?.length) {
      const devicesSortedById = response.data.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );

      return devicesSortedById;
    }
  } catch (error) {
    console.error("computers error", error);
  }
};
