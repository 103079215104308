import React, { useEffect, useRef, useState } from "react";

import { Box, Button, Stack } from "@mui/material";

const SessionTime = ({ session, sessionsList }) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  let interval = useRef();

  const startTimer = (startTime) => {
    const now = Date.now();
    const distance = now - startTime;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance <= 0) {
      clearInterval(interval.current);
    } else {
      setTimerDays(days);
      setTimerHours(hours);
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
    }
  };

  useEffect(() => {
    const localTimer = session.startTime ? Date.parse(session.startTime) : null;
    if (localTimer) {
      interval.current = setInterval(() => {
        startTimer(+localTimer);
      }, 1000);
    }

    return () => clearInterval(interval.current);
  }, [session.startTime, sessionsList]);
  return (
    <>
      {session.startTime && (
        <Button variant="text" sx={{cursor: "auto"}}>
          <Stack
            height={20}
            sx={{ alignItems: "center" }}
            spacing={2}
            direction={"row"}
          >
            <Box>
              <p>{timerDays}</p>
            </Box>
            <p>:</p>
            <Box>
              <p>{timerHours}</p>
            </Box>
            <p>:</p>
            <Box>
              <p>{timerMinutes}</p>
            </Box>
            <p>:</p>
            <Box>
              <p>{timerSeconds}</p>
            </Box>
          </Stack>
        </Button>
      )}
    </>
  );
};

export default SessionTime;
