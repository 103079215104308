import React from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { CiUser } from "react-icons/ci";
import { RiAdminFill } from "react-icons/ri";


const UserItem = ({ user }) => {
  const userIcon = {
    TENANT_USER: <CiUser size={40} color={"#233044"} />,
    TENANT_ADMIN: <RiAdminFill size={35} color={"#233044"} />,
    SYS_ADMIN: <RiAdminFill size={40} color={"#233044"} />,
  };

  const typographyHeaderStyles = {
    fontSize: "16px",
    display: "inline",
    fontWeight: 600,
  };

  const typographyBodyStyles = { fontSize: "16px", display: "inline" };

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar sx={{ backgroundColor: "white" }}>{userIcon[user.role]}</Avatar>
      </ListItemAvatar>
      <Stack spacing={1}>
        <ListItemText
          primary={
            <Stack spacing={1} component={"span"}>
              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"Name: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {user.Name}
                </Typography>
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"SamAccountName: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {user.SamAccountName}
                </Typography>
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"UserPrincipalName: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {user.UserPrincipalName}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"DistinguishedName: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {user.DistinguishedName}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"loginStatus: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {user.loginStatus}
                </Typography>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </ListItem>
  );
};

export default UserItem;
