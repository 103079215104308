import React from "react";
import SessionsAndProcesses from "../../SessionsAndProcesses";

const DeviceSessionsDetails = () => {
  return (
    <>
      <SessionsAndProcesses />
    </>
  );
};

export default DeviceSessionsDetails;
