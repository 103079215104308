import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import CreateCredentialDialog from "./CreateCredentialDialog";
import { RiLockPasswordFill } from "react-icons/ri";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const CredentialsTable = ({
  credentials,
  tenantName,
  setCredentialsList,
  osValue,
}) => {
  const [activePassword, setActivePassword] = useState(null);
  const [filteredCredentials, setFilteredCredentials] = useState([]);

  const handlePassIconClick = (e) => {
    const iconName = e.currentTarget.getAttribute("name");
    setActivePassword(iconName);
  };

  const deleteCredential = async (credential) => {
    try {
      await axios.delete(`${NGROK}/api/credentials/${credential.label}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (credentialToRemove) => {
    await deleteCredential(credentialToRemove);

    setCredentialsList((prev) =>
      prev.filter((credential) => credential.label !== credentialToRemove.label)
    );
  };

  useEffect(() => {
    const tenantCreds = credentials?.filter((cred) => {
      const credTenantName = cred.label.split("_")[0].toLowerCase();
      const credOs = cred.label.split("_")[1].toLowerCase();

      return (
        credTenantName.toLowerCase() === tenantName.toLowerCase() &&
        osValue.toLowerCase() === credOs.toLowerCase()
      );
    });

    setFilteredCredentials(tenantCreds);
  }, [credentials, osValue, tenantName]);

  return (
    <TableContainer
      sx={{
        height: "fit-content",
        marginTop: 2.5,
      }}
      component={Paper}
    >
      <Table
        sx={{
          height: "fit-content",
          "& td, & th": {
            border: "1px solid #233044",
          },
        }}
      >
        <TableHead sx={{ backgroundColor: "#233044" }}>
          <TableRow>
            <TableCell
              align="left"
              className="userName"
              sx={{ color: "white" }}
            >
              <Typography fontSize={17} fontWeight={600} align="center">
                Label
              </Typography>
            </TableCell>
            <TableCell
              align="left"
              className="userName"
              sx={{ color: "white" }}
            >
              <Typography fontSize={17} fontWeight={600} align="center">
                Username
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              className="userName"
              sx={{ color: "white", width: 100 }}
            >
              <Typography align="center" fontSize={17} fontWeight={600}>
                Password
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              className="userName"
              sx={{ color: "white", width: 100 }}
            >
              <Typography align="center" fontSize={17} fontWeight={600}>
                Management
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCredentials?.length
            ? filteredCredentials.map((credential) => (
                <TableRow
                  key={credential.label}
                  sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                >
                  <TableCell align="center" className="userName">
                    <Typography fontSize={17}>
                      {credential.label.split("_")[2]}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className="userName">
                    <Typography fontSize={17}>{credential.username}</Typography>
                  </TableCell>
                  <TableCell align="center" className="userName">
                    <Typography fontSize={17}>
                      {activePassword !== credential.label ? (
                        <RiLockPasswordFill
                          onClick={(e) => handlePassIconClick(e)}
                          name={credential.label}
                          color="#233044"
                          size={35}
                          cursor={"pointer"}
                        />
                      ) : (
                        credential.password
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className="userName"
                    sx={{ width: "fit-content" }}
                  >
                    <Stack
                      spacing={2}
                      direction={"row"}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <CreateCredentialDialog
                        setCredentialsList={setCredentialsList}
                        credential={credential}
                        tenantName={tenantName}
                        osValue={osValue}
                      />
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleSubmit(credential)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CredentialsTable;
