import React from "react";
import useUserStore from "../../../../services/userStore";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const AccessS3Buckets = ({ publishedS3Buckets, setPublishedS3Buckets }) => {
  const { role, userId } = useUserStore((state) => state.user);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "16px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>

              {role === "TENANT_USER" ? <TableCell>Action</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {publishedS3Buckets?.length > 0 ? (
              publishedS3Buckets.map((bucket, index) => (
                <TableRow key={index}>
                  <TableCell>{bucket.bucketName}</TableCell>
                  {role === "TENANT_USER" ? (
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {}}
                      >
                        Request Access
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body2">No Buckets available.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccessS3Buckets;
