import React, { useRef, useState } from "react";
import axios from "axios";
import { ButtonGroup, Button } from "@mui/material";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import { v4 as uuidv4 } from "uuid";
import CustomSnackbar from "../../CustomSnackbar";

/**
 * @param {Array<Object>} categoryList this displaying all the category. We are using name to compare unique categories.
 * Export - Downloading all the categories created using create new category dialog.
 * Import - Can select only JSON files And only displaying the Unique categories (On Console) which are not present on UI.
 *  setImportData - It contains the whole data of the file which us imported.
 */
const ExportImport = ({
  categoryList,
  resourceType,
  fetchCategories,
  os,
  tenantName,
}) => {
  const email = useUserStore((state) => state.user.email);

  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const changeHandler = (event) => {
    const fileObj = event.target.files && event.target.files[0];

    if (!fileObj) {
      console.error("No file selected.");
      event.target.value = null;
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const fileContents = e.target.result;
      let errorOccurred = false;

      try {
        const importCategories = JSON.parse(fileContents);

        if (!Array.isArray(importCategories)) {
          setOpen(true);
          setSnackbarMessage("File does not contain an array of objects.");
          setSnackbarSeverity("error");
          console.error("File does not contain an array of objects.");
          errorOccurred = true;
          return;
        }

        if (
          !importCategories.every(
            (category) => category.resourceType === resourceType.toUpperCase(),
          )
        ) {
          setOpen(true);
          setSnackbarMessage("Wrong resource type of imported categories.");
          setSnackbarSeverity("error");
          console.error("Wrong resource type of imported categories.");
          errorOccurred = true;
          return;
        }

        const categorySet = new Set(
          categoryList.map((category) => category.name),
        );
        const uniqCategories = importCategories.filter(
          (importCategory) => !categorySet.has(importCategory.name),
        );

        uniqCategories.forEach((item) => {
          if (item.operationSystem !== os) {
            setOpen(true);
            setSnackbarMessage("Wrong Operating System");
            setSnackbarSeverity("error");
            errorOccurred = true;
            return;
          }
        });

        if (!errorOccurred) {
          if (uniqCategories && uniqCategories.length) {
            const createCategoryPromises = uniqCategories.map((category) =>
              createCategory(category),
            );
            Promise.all(createCategoryPromises);

            setTimeout(() => {
              fetchCategories(os);
            }, 1000);
          } else {
            setOpen(true);
            setSnackbarMessage("Category Already Exist");
            setSnackbarSeverity("error");
          }
        }
      } catch (error) {
        setOpen(true);
        setSnackbarMessage("Error parsing JSON.");
        setSnackbarSeverity("error");
        console.error("Error parsing JSON:", error);
        errorOccurred = true;
      }
    };

    reader.readAsText(fileObj);
    event.target.value = null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const importData = () => {
    inputRef.current?.click();
  };
  const exportData = () => {
    const jsonBlob = new Blob([JSON.stringify(categoryList)], {
      type: "application/json",
    });

    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${resourceType}-${os}-categoryList.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const createCategory = async (category) => {
    const searchPatterns = category.searchPatterns.map((pattern) => ({
      id: uuidv4(),
      text: pattern,
    }));
    try {
      await axios.post(`${NGROK}/api/category`, {
        name: category.name,
        reputationScore: category.reputationScore,
        searchPatterns,
        os: category.operationSystem,
        resourceType: resourceType.toUpperCase(),
        profiles: category.profiles,
        userEmail: email,
        manualExclusions: category.manualExclusions || [],
        manualInclusions: category.manualInclusions || [],
        tenantName,
      });
    } catch (error) {
      console.error(error, "error during updating categories");
    }
  };

  return (
    <>
      <ButtonGroup
        aria-label="medium secondary button group"
        size="large"
        variant="contained"
        color="primary"
      >
        <Button onClick={importData}>Import</Button>

        <input
          type="file"
          accept=".json"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={(event) => changeHandler(event)}
        />
        <CustomSnackbar
          open={open}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          onClose={handleClose}
        />

        <Button disabled={!categoryList?.length} onClick={exportData}>
          Export
        </Button>
      </ButtonGroup>
    </>
  );
};
export default ExportImport;
