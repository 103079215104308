import React, { useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormLogo from "./components/FormLogo";
import SignInEmailCheck from "./components/SignInEmailCheck";
import SignInForm from "./components/SignInForm";
import AuthenticationPage from "./components/AuthenticationPage";
import { Grid, Link, Paper, Stack } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#233141",
    },
  },
});

const CustomSignIn = () => {
  const [activeForm, setActiveForm] = useState({
    isEmailActive: true,
    isSignInActive: false,
  });
  const [tenantName, setTenantName] = useState("");
  const [realmName, setRealmName] = useState("");

  const [email, setEmail] = useState("");

  const setEmailFormActive = () => {
    setActiveForm({
      isEmailActive: true,
      isSignInActive: false,
    });
  };

  const setPasswordFormActive = () => {
    setActiveForm({
      isEmailActive: false,
      isSignInActive: true,
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AuthenticationPage theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper
            elevation={5}
            sx={{
              maxHeight: 400,
              minHeight: 400,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: 3,
              borderRadius: 5,
            }}
          >
            <Stack
              spacing={2}
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: "50px !important",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  spacing={0.8}
                >
                  <LockOutlinedIcon
                    sx={{
                      color: "red",
                      fontSize: "2rem",
                    }}
                  />
                  <Typography
                    component="h1"
                    variant="h1"
                    sx={{
                      wordBreak: "break-word",
                      fontSize: "24px",
                      fontWeight: "bold",
                      letterSpacing: ".011em",
                      lineHeight: "normal",
                    }}
                  >
                    Sign in
                  </Typography>
                </Stack>
              </Box>
              <SignInEmailCheck
                togleActiveForm={setPasswordFormActive}
                toggleEmailActive={setEmailFormActive}
                setRealmName={setRealmName}
                setTenantName={setTenantName}
                email={email}
                setEmail={setEmail}
                activeForm={activeForm}
              />
              {activeForm.isSignInActive ? (
                <SignInForm
                  togleActiveForm={setEmailFormActive}
                  email={email}
                  tenantName={tenantName}
                  realmName={realmName}
                  setRealmName={setRealmName}
                />
              ) : null}
            </Stack>
            <Grid
              container
              justifyContent="center"
              sx={{
                mt: 2,
              }}
            >
              <Grid item>
                <Typography variant="body1">
                  Don't have an account?{" "}
                  <Link href="/signUp" variant="body2">
                    Sign up!
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </AuthenticationPage>
    </ThemeProvider>
  );
};

export default CustomSignIn;
