import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { CiSettings } from "react-icons/ci";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import { getUsers } from "../../api";

const ConfigureConnectionModal = ({ device, tenantName, getComputers }) => {
  const { userId, email, role } = useUserStore((state) => state.user);

  const [usersList, setUsersList] = useState([]);
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [accessPort, setAccessPort] = useState(device.accessPort);

  const setNewUser = async () => {
    try {
      const response = await axios.post(`${NGROK}/api/connection-auth`, {
        userId,
        username,
        role,
        computerId: device.id,
        accessPort: device.accessPort,
      });

      if (response.data) {
        getComputers(tenantName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = () => {
    setNewUser();

    handleClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      const usersData = await getUsers(tenantName, device.id, role, email);
      setUsersList(usersData);
    };

    if (open) fetchData();
  }, [device.id, email, open, role, tenantName]);

  useEffect(() => {
    const connectUser = device.connectionCreatedFor?.find(
      (user) => user.userId === userId && user.role === role
    );
    const deviceUser = usersList?.find(
      (user) => user.id === userId && user.role === role
    );

    if (connectUser) setUsername(connectUser.username);
    else if (deviceUser) setUsername(deviceUser.email);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  return (
    <>
      <CiSettings size={30} color={"#233044"} onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Connection configuration</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ padding: 2 }}>
            Enter username
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Username"
            type="text"
            fullWidth
            variant="outlined"
            name="Username"
            value={username}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            id="accessPort"
            label={"Port"}
            type="text"
            fullWidth
            variant="outlined"
            name="accessPort"
            value={accessPort}
          />

          {/* {errorMessage ? (
            <Alert sx={{ marginTop: 1, marginBottom: 1 }} severity="error">
              {errorMessage}
            </Alert>
          ) : null} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!username} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfigureConnectionModal;
