import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { NGROK } from "../../../../APIs";
import {
  sortOrderLinuxProfiles,
  sortOrderMacProfiles,
  sortOrderWindowsProfiles,
} from "../../../../services/Helpers";

const ProfileSelection = ({
  setSelectedProfile,
  selectedTenant,
  os,
  selectedProfile,
}) => {
  const [profileList, setProfileList] = useState([]);

  useEffect(() => {
    const fetchTenantProfiles = async (tenantName) => {
      try {
        const response = await axios.get(
          `${NGROK}/api/profiles/inherited?tenantName=${tenantName}`,
        );

        const linuxProfiles = response.data
          .filter((profile) => profile.os === "linux")
          .sort((a, b) => {
            const aIndex = sortOrderLinuxProfiles.indexOf(a.name);
            const bIndex = sortOrderLinuxProfiles.indexOf(b.name);
            return aIndex - bIndex;
          });
        const windowsProfiles = response.data
          .filter((profile) => profile.os === "windows")
          .sort((a, b) => {
            const aIndex = sortOrderWindowsProfiles.indexOf(a.name);
            const bIndex = sortOrderWindowsProfiles.indexOf(b.name);
            return aIndex - bIndex;
          });
        const macProfiles = response.data
          .filter((profile) => profile.os === "mac")
          .sort((a, b) => {
            const aIndex = sortOrderMacProfiles.indexOf(a.name);
            const bIndex = sortOrderMacProfiles.indexOf(b.name);
            return aIndex - bIndex;
          });

        if (os === "Windows") setProfileList(windowsProfiles);
        else if (os === "Linux") setProfileList(linuxProfiles);
        else if (os === "MacOS") setProfileList(macProfiles);
      } catch (error) {
        console.log(error, "error");
      }
    };

    if (selectedTenant) fetchTenantProfiles(selectedTenant.tenantName);
  }, [os, selectedTenant]);

  useEffect(() => {
    if (profileList.length) setSelectedProfile(profileList[0]);
  }, [profileList, setSelectedProfile]);

  return (
    <>
      {profileList.length && selectedProfile ? (
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedProfile.name}
        >
          {profileList?.map((profile) => (
            <FormControlLabel
              key={profile.name}
              value={profile.name}
              control={<Radio />}
              label={profile.name}
              onChange={() => setSelectedProfile(profile)}
            />
          ))}
        </RadioGroup>
      ) : null}
    </>
  );
};

export default ProfileSelection;
