import { AppsTableHeader } from "./AppsTableHeader";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import RadioAuthFilter from "../../../components/RadioAuthFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import ResourceRequestForm from "../../../components/ResourceRequestForm";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import {
  adminTrustLevelStyles,
  checkIfUserHasAccess,
  getFilteredEndpointsResources,
  getInheritedEndpointResourceStatusValue,
  sortObjectsAlphabetically,
  userTrustLevelStyles,
} from "../../../../services/Helpers";
import Timer from "../../../components/Timer";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useDebouncedValue from "../../../../hooks/useDebouncedValue";
import { BsFiletypeExe } from "react-icons/bs";

const EndpointsAppsTable = ({
  selectedUser,
  tenantName,
  selectedPublisher,
  showCount,
  setShowCount,
}) => {
  const tableRef = useRef();
  const appRef = useRef();
  const queryClient = useQueryClient();

  const userData = useUserStore((state) => state.user);
  const activeComputer =
    useUserStore((state) => state.activeComputer) ||
    myLocalStorage.getItem("activeComputer");

  const [timedAppsWithoutAccess, setTimedAppsWithoutAccess] = useState([]);
  const [appsSearchValues, setAppsSearchValues] = useState("");
  const [filterAppsValue, setFilterAppsValue] = useState(
    userData.role !== "TENANT_USER" ? "ALL" : "DENIED",
  );
  const [apps, setApps] = useState([]);

  const debouncedSearchTerm = useDebouncedValue(appsSearchValues, 1000);

  const fetchApplications = async (selectedPublisher, userId) => {
    const appArr = [];

    const getUrl = (publisherId) =>
      `${NGROK}/api/${tenantName}/computer-users/${userId}/publishers/${publisherId}/apps`;

    const fetchData = async (url) => {
      const result = await axios.get(url);
      if (result.data) appArr.push(result.data.content);
    };

    if (selectedPublisher.friendlyName !== "Unsigned Apps") {
      await Promise.all(
        selectedPublisher?.ids?.map((publisherId) =>
          fetchData(getUrl(publisherId)),
        ),
      );
    } else {
      await fetchData(getUrl(selectedPublisher?.id));
    }

    return appArr.flat();
  };

  const changeAppTrustLevel = async ({ application, groupStatus }) => {
    const timeInSeconds = activeComputer.defaultTime * 60;

    try {
      return await axios.put(
        `${NGROK}/api/${tenantName}/computer-user/publishers/apps/timer`,
        {
          userId: application.computerUserId,
          publisherId: application.publisherId,
          appId: application.appId,
          email: userData.email,
          role: userData.role,
          groupStatus,
          selectedTime: timeInSeconds,
        },
      );
    } catch (error) {
      console.error(error);
      return application;
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterAppsValue(e.target.value);
  };

  const checkIfTimeShouldBeShown = (application) => {
    if (
      application.groupStatus === "DYNAMIC" ||
      (application.inheritedGroupStatus === "DYNAMIC" &&
        application.groupStatus === "INHERITED")
    ) {
      return true;
    } else return false;
  };

  const getAllApplications = async (selectedUserId, activeComputerId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/computer-user/get-all-apps?userId=${selectedUserId}&computerId=${activeComputerId}`,
      );

      return response.data?.content;
    } catch (error) {
      console.error(error);
    }
  };

  const allAppsParameteresAreReady =
    !!selectedUser &&
    !!activeComputer &&
    !!(selectedPublisher.id === "allAppsPublisher");

  const endpointAppsParameteresAreReady =
    !!selectedUser &&
    !!selectedPublisher &&
    !!(selectedPublisher.id !== "allAppsPublisher");

  const { data: allApps, isLoading: allAppsAreLoading } = useQuery({
    queryKey: ["allApps", selectedUser.id, activeComputer.id],
    queryFn: () => getAllApplications(selectedUser.id, activeComputer.id),
    enabled: allAppsParameteresAreReady,
    refetchInterval: 5000,
  });

  const { data: publisherApps, isLoading: publisherAppsAreLoading } = useQuery({
    queryKey: ["publisherApps", selectedPublisher, selectedUser.id],
    queryFn: () => fetchApplications(selectedPublisher, selectedUser.id),
    enabled: endpointAppsParameteresAreReady,
  });

  const updateTrustLevelMutation = useMutation({
    mutationFn: changeAppTrustLevel,
    onSuccess: async ({ data: newObj }) => {
      try {
        await queryClient.setQueryData(
          ["allApps", selectedUser.id, activeComputer.id],
          (oldQueryData) => {
            return oldQueryData?.map((item) =>
              item.appId === newObj.appId ? newObj : item,
            );
          },
        );
        await queryClient.setQueryData(
          ["publisherApps", selectedPublisher, selectedUser.id],
          (oldQueryData) => {
            return oldQueryData?.map((item) =>
              item.appId === newObj.appId ? newObj : item,
            );
          },
        );
      } catch (error) {
        console.error("Error setting query data:", error);
      }
    },
  });

  const findApplication = (value) => {
    setAppsSearchValues(value);
  };

  useEffect(() => {
    if (allApps && selectedPublisher.id === "allAppsPublisher")
      setApps(allApps);
    else if (publisherApps) setApps(publisherApps);
    else setApps([]);
  }, [allApps, publisherApps, selectedPublisher.id]);

  const getUserApps = () => {
    const timedApps = sortObjectsAlphabetically(
      getFilteredEndpointsResources(debouncedSearchTerm, "TIMED", apps),
      "appName",
    );

    const deniedApps = sortObjectsAlphabetically(
      getFilteredEndpointsResources(debouncedSearchTerm, "DENIED", apps),
      "appName",
    );

    return [...timedApps, ...deniedApps];
  };

  const getApplications = () => {
    const filteredApplications =
      userData?.role !== "TENANT_USER"
        ? sortObjectsAlphabetically(
            getFilteredEndpointsResources(
              debouncedSearchTerm,
              filterAppsValue,
              apps,
            ),
            "appName",
          )
        : getUserApps();

    const unicApps = filteredApplications?.reduce((accumulator, current) => {
      if (!accumulator.find((app) => app.path === current.path)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return unicApps;
  };
  const handleScroll = () => {
    if (tableRef.current) {
      const table = tableRef.current;
      const rows = table.querySelectorAll("tr");
      if (rows.length >= showCount) {
        const fiftiethRow = rows[showCount - 1];
        const rect = fiftiethRow.getBoundingClientRect();
        const tableRect = table.getBoundingClientRect();
        if (
          rect.top >= tableRect.top &&
          rect.bottom <= tableRect.bottom &&
          getApplications()?.length > showCount
        ) {
          setShowCount((prevCount) => prevCount + 50);
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef.current, apps, showCount]);

  if (publisherAppsAreLoading || allAppsAreLoading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={"fit-content"}>
      {apps?.length ? (
        <Stack direction={"column"} spacing={2} width={"100%"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ padding: "15px" }}
          >
            {userData?.role !== "TENANT_USER" ? (
              <RadioAuthFilter
                userRole={userData?.role}
                filterResourceValue={filterAppsValue}
                filterName={"Filter by privilege"}
                handleFilterResourceValue={handleFilterResourceValue}
              />
            ) : null}
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForApplication"
              value={appsSearchValues}
              onChange={(e) => findApplication(e.target.value)}
            />
          </Box>
          <Typography
            variant="h6"
            color="initial"
            fontWeight={600}
            align="center"
          >
            Total Count: {getApplications()?.length || "0"}
          </Typography>

          <TableContainer
            component={Paper}
            ref={tableRef}
            sx={{
              overflowX: "auto",
            }}
          >
            <Table
              sx={{
                width: "100%",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
              size="large"
            >
              <AppsTableHeader appRef={appRef} />
              <TableBody>
                {getApplications()
                  .slice(0, showCount)
                  .map((application) => (
                    <TableRow className="groupRow" key={application.appId}>
                      <StyledTooltip
                        arrow
                        title={
                          <>
                            <Typography color="inherit">
                              {`Path: ${application.path}`}
                            </Typography>
                          </>
                        }
                        placement="left"
                      >
                        <TableCell className="userName">
                          <Stack
                            direction={"row"}
                            spacing={2}
                            alignItems="center"
                          >
                            <span>
                              <BsFiletypeExe style={{ fontSize: "1rem" }} />
                            </span>
                            <span>{application.appName}</span>
                          </Stack>
                        </TableCell>
                      </StyledTooltip>
                      <TableCell className="privilegeLevelTableCell">
                        <Box
                          className={
                            userData.role === "TENANT_USER"
                              ? userTrustLevelStyles(application)
                              : adminTrustLevelStyles(application)
                          }
                        >
                          <Select
                            disabled={userData.role === "TENANT_USER"}
                            sx={{ minWidth: "100px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              application.groupStatus
                                ? application.groupStatus
                                : ""
                            }
                            size="small"
                            onChange={(event) =>
                              updateTrustLevelMutation.mutate({
                                application,
                                groupStatus: event.target.value,
                              })
                            }
                          >
                            <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                            <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                            <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                            <MenuItem value={"INHERITED"}>
                              INHERITED{" "}
                              {getInheritedEndpointResourceStatusValue(
                                application,
                              )}
                            </MenuItem>
                          </Select>
                          {checkIfTimeShouldBeShown(application) ? (
                            <Timer
                              setTimedResourcesWithoutAccess={
                                setTimedAppsWithoutAccess
                              }
                              resourceId={application.appId}
                              seconds={application.remainingTime}
                            />
                          ) : null}
                          {!checkIfUserHasAccess(
                            application,
                            userData,
                            timedAppsWithoutAccess,
                            application.appId,
                          ) ? (
                            <ResourceRequestForm
                              publisherId={application.publisherId}
                              tenantName={tenantName}
                              resourceName={application.path}
                              resourcePath={application.path}
                              selectedUser={selectedUser}
                              resourceId={application.appId}
                              resourceType={"APP"}
                              computer={activeComputer}
                              isFromEndPointApp={true}
                            />
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {getApplications()?.length > showCount ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : (
        <Typography variant="h6" color="initial">
          There are no applications.
        </Typography>
      )}
    </Box>
  );
};

export default EndpointsAppsTable;
