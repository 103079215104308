import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { NGROK } from "../../../../APIs";
import axios from "axios";

const ConfigureDevice = ({ open, onClose, selectedTenant }) => {
  const [ipAddress, setIpAddress] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [protocol, setProtocol] = useState("");
  const [portNumber, setPortNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isIPValid, setIsIPValid] = useState(false);
  const [ipError, setIpError] = useState("");
  const [validationComplete, setValidationComplete] = useState(false);
  const [gatewayComputerId, setGatewayComputerId] = useState(-1);
  const [isSuccessdeviceCreation, setIsSuccessdeviceCreation] = useState(false);
  const [isFaileddeviceCreation, setIsFaileddeviceCreation] = useState(false);
  const [deviceIPValidateError, setDeviceIPValidateError] = useState("");
  const [validateError, setValidateError] = useState(false);
  const [gatewayComputerName, setGatewayComputerName] = useState("");

  const protocols = ["SSH", "RDP"];

  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const validateFields = () => {
    const isValidIp = ipAddress !== "" && isIPValid;
    const isPasswordMatch = password === confirmPassword && password !== "";
    const isValidForm =
      isValidIp &&
      deviceName &&
      protocol &&
      portNumber &&
      username &&
      isPasswordMatch;

    setIsValid(isValidForm);
  };

  const validateIp = (value) => {
    const isValidIp = ipRegex.test(value);
    setIpError(isValidIp ? "" : "Invalid IP Address format");
    setIsIPValid(isValidIp);
  };

  const handleValidate = async (ipAddress, deviceName) => {
    if (isIPValid && deviceName) {
      const response = await axios.get(
        `${NGROK}/api/validate-device?tenantName=${selectedTenant.tenantName}&ipAddress=${ipAddress}&deviceName=${deviceName}`,
      );
      const data = response.data;
      if (data.isValid) {
        setValidationComplete(true);
        setGatewayComputerId(data.gatewayComputerId);
        setValidateError(false);
        setProtocol("SSH");
        setPortNumber(22);
        setGatewayComputerName(data.gatewayName);
      } else {
        setValidationComplete(false);
        setGatewayComputerId(-1);
        setDeviceIPValidateError(data.error);
        setValidateError(true);
        setGatewayComputerName("");
      }
    }
  };
  const addNewManualdevice = async () => {
    const data = {
      ipAddress: ipAddress,
      gatewayComputerId: gatewayComputerId,
      tenantName: selectedTenant.tenantName,
      deviceName: deviceName,
      protocol: protocol,
      portNumber: portNumber,
      username: username,
      password: password,
      confirmPassword: confirmPassword,
    };
    await axios
      .post(`${NGROK}/api/add-manual-device`, data)
      .then((resp) => {
        setIsSuccessdeviceCreation(true);
        setIsFaileddeviceCreation(false);
      })
      .catch((err) => {
        console.error(err);
        setIsSuccessdeviceCreation(false);
        setIsFaileddeviceCreation(true);
      });
  };

  useEffect(() => {
    validateFields();
  }, [
    ipAddress,
    deviceName,
    protocol,
    portNumber,
    username,
    password,
    confirmPassword,
  ]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Device Configuration</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="IP Address"
                  value={ipAddress}
                  onChange={(e) => {
                    validateIp(e.target.value);
                    setIpAddress(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                  error={!!ipError}
                  helperText={ipError}
                  InputProps={{
                    endAdornment:
                      validationComplete && isIPValid ? (
                        <InputAdornment position="end">
                          <IconButton>
                            <CheckCircle color="success" />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                />
                {validationComplete && isIPValid && gatewayComputerName ? (
                  <FormHelperText>
                    Gateway computer: {gatewayComputerName}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Device Name"
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment:
                    validationComplete && deviceName ? (
                      <InputAdornment position="end">
                        <IconButton>
                          <CheckCircle color="success" />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
              />
            </Grid>
          </Grid>
          {validateError && (
            <Alert
              onClose={() => setValidateError(false)}
              severity="error"
              variant="filled"
              sx={{ width: "100%", marginTop: 1 }}
            >
              {deviceIPValidateError}
            </Alert>
          )}
          {!validationComplete && (
            <Button
              onClick={() => handleValidate(ipAddress, deviceName)}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ mt: 2 }}
              disabled={!isIPValid || !deviceName}
            >
              Validate
            </Button>
          )}

          {validationComplete && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Autocomplete
                  options={protocols}
                  getOptionLabel={(option) => option}
                  value={protocol}
                  onChange={(event, newValue) => {
                    if (newValue === "SSH") {
                      setPortNumber("22");
                    } else if (newValue === "RDP") {
                      setPortNumber("3389");
                    }
                    setProtocol(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Protocol"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  defaultValue={"22"}
                  label="Port Number"
                  value={portNumber}
                  onChange={(e) => setPortNumber(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => addNewManualdevice()}
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {isFaileddeviceCreation && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setIsFaileddeviceCreation(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsFaileddeviceCreation(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {"Failed to create manual device"}
          </Alert>
        </Snackbar>
      )}

      {isSuccessdeviceCreation && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setIsSuccessdeviceCreation(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsSuccessdeviceCreation(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {"Manual device added successfully"}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ConfigureDevice;
