import React, { useState } from "react";

import ".//../pages.scss";
import useUserStore from "../../../services/userStore";

import EndpointsPublishersTable from "./components/EndpointsPublishersTable";
import EndpointsAppsTable from "./components/EndpointsAppsTable";
import { myLocalStorage } from "../../../components/StorageHelper";

const ApplicationsControl = ({
  tenantName,
  selectedUser,
  showCount,
  setShowCount,
}) => {
  const userRole = useUserStore((state) => state.user.role);
  const latestPublisher = myLocalStorage.getItem("lastSelectedPublisher");

  const allAppsPublisher = {
    id: "allAppsPublisher",
    friendlyName: "allAppsPublisher",
    ids: ["allAppsPublisher"],
  };

  const [selectedPublisher, setSelectedPublisher] = useState(
    userRole === "TENANT_USER" ? allAppsPublisher : latestPublisher
  );

  return (
    <section className={userRole !== "TENANT_USER" ? "applicationTab" : null}>
      {userRole !== "TENANT_USER" ? (
        <EndpointsPublishersTable
          allAppsPublisher={allAppsPublisher}
          selectedPublisher={selectedPublisher}
          setSelectedPublisher={setSelectedPublisher}
          tenantName={tenantName}
        />
      ) : null}

      {selectedPublisher ? (
        <EndpointsAppsTable
          showCount={showCount}
          setShowCount={setShowCount}
          selectedPublisher={selectedPublisher}
          selectedUser={selectedUser}
          tenantName={tenantName}
        />
      ) : null}
    </section>
  );
};

export default ApplicationsControl;
