import React, { useState } from "react";
import { Stack, Box, Typography, Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CategoryItem from "./CategoryItem";
import { myLocalStorage } from "../../../../components/StorageHelper";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

const Categories = () => {
  const location = useLocation();

  const locationState = location?.state;

  const profile =
    locationState?.defaultProfile || myLocalStorage.getItem("selectedProfile");
  const tenant =
    locationState?.selectedTenant || myLocalStorage.getItem("latestTenant");

  const [tabValue, setTabValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Stack spacing={3}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Applications"
              {...a11yProps(0)}
              icon={<AppsOutlinedIcon />}
              iconPosition="start"
            />
            <Tab
              label="Folders"
              {...a11yProps(1)}
              icon={<FolderOutlinedIcon />}
              iconPosition="start"
            />
            {/* <Tab label="Privileges " {...a11yProps(2)} />
            <Tab label="Publishers" {...a11yProps(3)} /> */}
            <Tab
              label="URL control"
              {...a11yProps(4)}
              icon={<LinkOutlinedIcon />}
              iconPosition="start"
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <CategoryItem
            resourceType={"App"}
            selectedTenant={tenant}
            profile={profile}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <CategoryItem
            resourceType={"Folder"}
            selectedTenant={tenant}
            profile={profile}
          />
        </CustomTabPanel>
        {/* <CustomTabPanel value={tabValue} index={2}>
          <CategoryItem
            resourceType={"Group"}
            selectedTenant={tenant}
            profile={profile}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={3}>
          <CategoryItem
            resourceType={"Publisher"}
            selectedTenant={tenant}
            profile={profile}
          />
        </CustomTabPanel> */}
        <CustomTabPanel value={tabValue} index={2}>
          <CategoryItem
            resourceType={"URLcontrol"}
            selectedTenant={tenant}
            profile={profile}
          />
        </CustomTabPanel>
      </Box>
    </Stack>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default Categories;
