import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";

import { NGROK } from "../../../APIs";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ".//../pages.scss";
import { MenuItem, Select, Snackbar, Table, Typography } from "@mui/material";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import useUserStore from "../../../services/userStore";
import ResourceRequestForm from "../../components/ResourceRequestForm";
import MuiAlert from "@mui/material/Alert";
import Timer from "../../components/Timer";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import {
  adminTrustLevelStyles,
  checkIfUserHasAccess,
  getFilteredEndpointsResources,
  getInheritedEndpointResourceStatusValue,
  userTrustLevelStyles,
} from "../../../services/Helpers";
import { myLocalStorage } from "../../../components/StorageHelper";
import useDebouncedValue from "../../../hooks/useDebouncedValue";

const PrivilegeManagement = ({
  tenantName,
  showCount,
  setShowCount,
  selectedUser,
  setGroupsSearchValues,
  groupsSearchValues,
}) => {
  const tableRef = useRef();

  const activeComputer =
    useUserStore((state) => state.activeComputer) ||
    myLocalStorage.getItem("activeComputer");
  const userData = useUserStore((state) => state.user);

  const [filterGroupValue, setFilterGroupValue] = useState(
    userData.role !== "TENANT_USER" ? "ALL" : "DENIED",
  );
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [timedGroupsWithoutAccess, setTimedGroupsWithoutAccess] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const { vertical, horizontal, open } = snackbarSettings;
  const selectedTenantName =
    tenantName || myLocalStorage.getItem("latestTenant")?.tenantName;

  const debouncedSearchTerm = useDebouncedValue(groupsSearchValues, 1000);

  const changeGroupStatusOnUi = (newGroup) => {
    const updatedGroups = groups.map((group) =>
      group.adComputerUser.id === newGroup.adComputerUser.id &&
      group.adComputerUserGroup.id === newGroup.adComputerUserGroup.id
        ? newGroup
        : group,
    );

    setGroups(updatedGroups);
  };

  const updateGroupStatus = async (group, groupStatus, inputTime) => {
    const timeInSeconds = (inputTime || activeComputer.defaultTime) * 60;

    try {
      const response = await axios.put(
        `${NGROK}/api/${tenantName}/computer-user-groups/membership?computerId=${activeComputer.id}`,
        {
          userId: group.adComputerUser.id,
          groupId: group.adComputerUserGroup.id,
          groupStatus,
          selectedTime: timeInSeconds,
          email: userData.email,
          role: userData.role,
        },
      );

      if (!response.data) {
        changeGroupStatusOnUi(group);
      } else changeGroupStatusOnUi(response.data);
    } catch (error) {
      changeGroupStatusOnUi(group);
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterGroupValue(e.target.value);
  };

  const getUserGroups = () => {
    const timedGroups = getFilteredEndpointsResources(
      debouncedSearchTerm,
      "TIMED",
      groups,
    );

    const deniedGroups = getFilteredEndpointsResources(
      debouncedSearchTerm,
      "DENIED",
      groups,
    );

    return [...timedGroups, ...deniedGroups];
  };

  const getFilterdGroups = () => {
    const filteredGroups =
      userData?.role !== "TENANT_USER"
        ? getFilteredEndpointsResources(
            debouncedSearchTerm,
            filterGroupValue,
            groups,
          )
        : getUserGroups();
    return filteredGroups;
  };

  const checkIfTimeShouldBeShown = (group) => {
    if (
      (group.groupStatus === "DYNAMIC" && group.remainingTime > 0) ||
      (group.inheritedGroupStatus === "DYNAMIC" &&
        group.groupStatus === "INHERITED")
    ) {
      return true;
    } else return false;
  };

  const findGroup = (value) => {
    setGroupsSearchValues(value);
  };
  const getGroups = async (isFirst = false) => {
    if (isFirst) setLoading(true);

    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenantName}/computer-user-groups/membership/${selectedUser?.id}`,
      );
      setGroups(response.data.content);
    } catch (error) {
      setGroups([]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedUser) getGroups(true);
    const interval = setInterval(() => {
      getGroups();
    }, 5000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantName, selectedUser]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const table = tableRef.current;
        const rows = table.querySelectorAll("tr");

        if (rows.length >= showCount) {
          const fiftiethRow = rows[showCount - 1];
          const rect = fiftiethRow.getBoundingClientRect();
          const tableRect = table.getBoundingClientRect();
          if (
            rect.top >= tableRect.top &&
            rect.bottom <= tableRect.bottom &&
            getFilterdGroups()?.length > showCount
          ) {
            setShowCount((prevCount) => prevCount + 50);
          }
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef, groups, showCount, getFilterdGroups, setShowCount]);

  if (loading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={"fit-content"}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() =>
          setSnackbarSettings({ ...snackbarSettings, open: false })
        }
        message="You can't make this resource inherited"
        key={vertical + horizontal}
      >
        <MuiAlert
          sx={{ fontSize: "18px", fontWeight: "500" }}
          severity="warning"
        >
          You can't make this resource inherited
        </MuiAlert>
      </Snackbar>
      {groups && groups[0] && groups[0].adComputerUserGroup ? (
        <Stack spacing={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ padding: "15px" }}
          >
            {userData?.role !== "TENANT_USER" ? (
              <RadioAuthFilter
                userRole={userData?.role}
                filterResourceValue={filterGroupValue}
                filterName={"Filter by privilege"}
                handleFilterResourceValue={handleFilterResourceValue}
              />
            ) : null}
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForApplication"
              value={groupsSearchValues}
              onChange={(e) => findGroup(e.target.value)}
            />
          </Box>
          <Typography
            variant="h6"
            color="initial"
            fontWeight={600}
            align="center"
          >
            Total Count: {getFilterdGroups()?.length || "0"}
          </Typography>

          <TableContainer
            component={Paper}
            ref={tableRef}
            sx={{
              height: "fit-content !important",
              maxHeight: "100vh !important",
              overflowY: "auto !important",
            }}
          >
            <Table
              sx={{
                width: "100%",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Groups
                  </MyHeaderTableCell>

                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Privilege Level
                  </MyHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getFilterdGroups()
                  ?.slice(0, showCount)
                  .map((group) => (
                    <TableRow
                      className="groupRow"
                      key={group.adComputerUserGroup.cn}
                    >
                      <TableCell
                        component="td"
                        scope="row"
                        className="groupRowName userName"
                        title={group.adComputerUserGroup.cn}
                        sx={{
                          minWidth: "500px !important",
                          maxWidth: "500px !important",
                        }}
                      >
                        {group.adComputerUserGroup.cn}
                      </TableCell>
                      <TableCell className="privilegeLevelTableCell">
                        <Box
                          className={
                            userData.role === "TENANT_USER"
                              ? userTrustLevelStyles(group)
                              : adminTrustLevelStyles(group)
                          }
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Select
                            disabled={userData.role === "TENANT_USER"}
                            sx={{ minWidth: "100px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={group.groupStatus ? group.groupStatus : ""}
                            size="small"
                            onChange={(event) =>
                              updateGroupStatus(group, event.target.value)
                            }
                          >
                            <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                            <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                            <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                            <MenuItem value={"INHERITED"}>
                              INHERITED{" "}
                              {getInheritedEndpointResourceStatusValue(group)}
                            </MenuItem>
                          </Select>
                          {checkIfTimeShouldBeShown(group) ? (
                            <Timer
                              setTimedResourcesWithoutAccess={
                                setTimedGroupsWithoutAccess
                              }
                              resourceId={group.adComputerUserGroup.id}
                              seconds={group.remainingTime}
                            />
                          ) : null}
                          {!checkIfUserHasAccess(
                            group,
                            userData,
                            timedGroupsWithoutAccess,
                            group.adComputerUserGroup.id,
                          ) ? (
                            <ResourceRequestForm
                              tenantName={tenantName}
                              selectedUser={selectedUser}
                              resourceId={group.adComputerUserGroup.id}
                              resourceType={"GROUP"}
                              resourceName={group.adComputerUserGroup.cn}
                              computer={activeComputer}
                            />
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {getFilterdGroups()?.length > showCount ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : groups === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any groups.
          </p>
        </Box>
      )}
    </Box>
  );
};

export default PrivilegeManagement;
