import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  Divider,
  Box,
  ListItemSecondaryAction,
  CircularProgress,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { spacing } from "@mui/system";
import styled from "styled-components";
import InstallDesktopOutlinedIcon from "@mui/icons-material/InstallDesktopOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import DesktopAccessDisabledOutlinedIcon from "@mui/icons-material/DesktopAccessDisabledOutlined";
import Brightness7OutlinedIcon from "@mui/icons-material/Brightness7Outlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import RemoveFromQueueOutlinedIcon from "@mui/icons-material/RemoveFromQueueOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { makeStyles } from "@mui/styles";
import { formatDateTime } from "../../../GenericMethods";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 1025px;
`;
const useStyles = makeStyles({
  root: {
    height: "1000px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});
const RecentActivity = ({ data }) => {
  const [recentActivityData, setrecentActivityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getActivity(10);
  };

  const getIcons = (val) => {
    switch (val) {
      case "audit":
        return (
          <VerifiedUserOutlinedIcon
            style={{
              color: getIconsColor(val),
              fontSize: 30,
            }}
          />
        );
      case "install":
        return (
          <InstallDesktopOutlinedIcon
            style={{
              color: getIconsColor(val),
              fontSize: 30,
            }}
          />
        );
      case "enforce":
        return (
          <DesktopAccessDisabledOutlinedIcon
            style={{
              color: getIconsColor(val),
              fontSize: 30,
            }}
          />
        );
      case "uninstall":
        return (
          <RemoveFromQueueOutlinedIcon
            style={{
              color: getIconsColor(val),
              fontSize: 30,
            }}
          />
        );
      case "alert":
        return (
          <WarningAmberOutlinedIcon
            style={{
              color: getIconsColor(val),
              fontSize: 30,
            }}
          />
        );
      default:
        return (
          <Brightness7OutlinedIcon
            style={{
              color: getIconsColor(val),
              fontSize: 30,
            }}
          />
        );
    }
  };
  const getIconsColor = (val) => {
    switch (val) {
      case "audit":
        return "blue";
      case "install":
        return "green";
      case "enforce":
        return "red";
      case "uninstall":
        return "red";
      case "alert":
        return "orange";
      default:
        return "blue";
    }
  };
  const getActivity = (limit) => {
    if (data) {
      data.sort((a, b) => {
        return new Date(b.time) - new Date(a.time);
      });
      const startIndex = (page - 1) * limit;
      const endIndex = startIndex + limit;

      const recentActivity = data.slice(startIndex, endIndex);
      setrecentActivityData(recentActivity);
      setLoading(false);
    }
  };

  useEffect(() => {
    getActivity(10);
  }, [data, page]);

  return (
    <>
      <Card>
        <CardHeader title="Recent Activity" />
        <CardContent>
          <ChartWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <List
                  sx={{ bgcolor: "background.paper" }}
                  className={classes.root}
                >
                  {recentActivityData !== null && (
                    <>
                      {recentActivityData.map((dataVal, index) => (
                        <div key={index}>
                          <ListItem
                            alignItems="flex-start"
                            sx={{
                              py: 3,
                              px: 3,
                              maxWidth: 500,
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt={dataVal?.resourceType}
                                style={{ backgroundColor: "transparent" }}
                              >
                                {getIcons(dataVal?.resourceType?.toLowerCase())}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="body1" fontWeight="bold">
                                  {dataVal?.message}
                                </Typography>
                              }
                              secondary={
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ComputerOutlinedIcon />
                                          <div
                                            style={{
                                              marginLeft: 5,
                                            }}
                                          >
                                            {dataVal?.computerName?.length > 0
                                              ? dataVal?.computerName
                                              : "localhost"}
                                          </div>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <PersonOutlineOutlinedIcon />
                                          <div
                                            style={{
                                              marginLeft: 5,
                                            }}
                                          >
                                            {dataVal?.computerUserEmail
                                              ?.length > 0
                                              ? dataVal?.computerUserEmail
                                              : "localhost"}
                                          </div>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <FolderOutlinedIcon />
                                          <div
                                            style={{
                                              marginLeft: 5,
                                            }}
                                          >
                                            {dataVal?.resourceName?.length > 0
                                              ? dataVal?.resourceName
                                              : "localhost"}
                                          </div>
                                        </Box>
                                      </Box>
                                    </Typography>
                                  </Box>
                                </>
                              }
                            />
                            <ListItemSecondaryAction>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <AccessTimeOutlinedIcon />
                                  <div
                                    style={{
                                      marginLeft: 5,
                                    }}
                                  >
                                    {formatDateTime(dataVal?.time)}
                                  </div>
                                </Box>
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </div>
                      ))}
                    </>
                  )}
                </List>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{
                    mb: 2,
                  }}
                >
                  {Math.ceil(data.length / 10) >= 2 && (
                    <Pagination
                      count={Math.ceil(data.length / 10)}
                      page={page}
                      onChange={handleChangePage}
                      variant="outlined"
                      shape="rounded"
                    />
                  )}
                </Box>
              </>
            )}
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default RecentActivity;
