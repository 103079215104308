import React, { useState } from "react";
import {
  Button,
  Box,
  Grid,
  Link,
  TextField,
  Alert,
  InputAdornment,
  CircularProgress,
  IconButton,
} from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import useUserStore from "../../../../services/userStore";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { setInitialSessionTime } from "../../../../services/Helpers";
import {
  decodeKeycloakToken,
  sendTokenToBackend,
} from "../../../../utils/tokenHelpers";
import { getClientId } from "../../../../services/Auth";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SignInForm = ({ togleActiveForm, email, realmName }) => {
  const { signIn } = useAuth();

  const fetchUserData = useUserStore((state) => state.getUserData);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (e) => {
    setPassword(e.target.value);
    setIsSubmitted(false);
    setPasswordError("");
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitLogin(e);
    }
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setSubmitLoading(true);
    const clientId = getClientId(realmName);

    try {
      const loginData = {
        username: email,
        password,
        realmName,
        clientId,
      };

      const { access_token, refresh_token, userInfo } = await signIn(loginData);

      myLocalStorage.setItem("access_token", access_token);
      myLocalStorage.setItem("refresh_token", refresh_token);
      myLocalStorage.setItem("email", email);

      const { exp } = decodeKeycloakToken(access_token);
      sendTokenToBackend(realmName, email, userInfo.role, access_token, exp);
      setInitialSessionTime(30);
      fetchUserData(email);
    } catch (error) {
      console.error(error);
      setPasswordError("Wrong password");
    }
    setSubmitLoading(false);
  };

  return (
    <Box sx={{ mt: 1 }} width={"100%"}>
      <TextField
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {submitLoading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  variant="contained"
                  onClick={(e) => submitLogin(e)}
                  sx={{
                    borderRadius: "50%",
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {passwordError && isSubmitted ? (
        <Alert sx={{ marginTop: 1 }} severity="error">
          {passwordError}
        </Alert>
      ) : null}
    </Box>
  );
};

export default SignInForm;
