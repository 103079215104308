import React from "react";
import { Button, Stack } from "@mui/material";

const TestPage = () => {


  return (
    <Stack direction={"row"} spacing={2}>
      <Button o variant="contained">
        Send notificication to slack
      </Button>
      <Button variant="contained">
        Get channels
      </Button>
    </Stack>
  );
};

export default TestPage;
