import jsPDF from "jspdf";
import moment from "moment";
import Logo from "../../../../vendor/Whiteswan-Security-logo.png";
import autoTable from "jspdf-autotable";

export const handleGeneratePDF = async (dateRange, logs, searchTerm) => {
  const pdf = new jsPDF();
  const title = "Logs";
  const titleWidth = pdf.getTextWidth(title);
  const pageWidth = pdf.getPageWidth();
  const titleX = (pageWidth - titleWidth) / 2;

  const startDate = dateRange[0]
    ? moment(dateRange[0]).format("DD.MM.YYYY")
    : "-";
  const endDate = dateRange[1]
    ? moment(dateRange[1]).format("DD.MM.YYYY")
    : "-";

  const imgX = (pageWidth - 100) / 2;

  pdf.setFillColor(35, 48, 68);
  pdf.rect(0, 0, pdf.internal.pageSize.width, 40, "F");

  pdf.addImage(Logo, "png", imgX, 5, 100, 30);

  const startNewPage = () => {
    if (pdf) pdf.addPage();
  };

  const tenantNames = [...new Set(logs.map((log) => log.tenantName))];

  tenantNames.forEach((tenantName, index) => {
    let startY = index === 0 ? 50 : 10;

    const tenantLogs = logs.filter((log) => log.tenantName === tenantName);
    pdf.text("Logs", titleX, startY);
    pdf.text(`Tenant Name: ${tenantName}`, 10, startY + 10);
    startDate && pdf.text(`Start Date: ${startDate}`, 10, startY + 20);
    endDate && pdf.text(`End Date: ${endDate}`, 10, startY + 30);
    pdf.text(`Search Term: "${searchTerm || "-"}"`, 10, startY + 40);

    const tableBody = [
      ...tenantLogs.map((log, index) => [
        index + 1,
        moment(log.time).format("DD.MM.YYYY HH:mm"),
        log.computerUserEmail,
        log.computerName,
        log.resourceType,
        log.resourceName,
        log.message,
      ]),
    ];

    autoTable(pdf, {
      startY: startY + 50,
      headStyles: { fillColor: [35, 48, 68], halign: "center" },
      head: [
        [
          "Sl No",
          "Time",
          "User",
          "Computer Name",
          "Type",
          "Resource Name",
          "Action",
        ],
      ],
      body: tableBody,
      bodyStyles: { halign: "center" },

      autoSize: false,
      margin: { top: 5, right: 0, bottom: 0, left: 0 },
      columnStyles: {
        0: { cellWidth: 10, halign: "center" },
        1: { cellWidth: 20, halign: "center" },
        2: { cellWidth: 30, halign: "center" },
        3: { cellWidth: 30, halign: "center" },
        4: { cellWidth: 20, halign: "center" },
        5: { cellWidth: 30, halign: "center" },
        6: { minCellWidth: 50, halign: "center" },
      },
    });
    startNewPage();
  });

  pdf.save("Reports.pdf");
};
