export const myLocalStorage = {
  getItem(keyName) {
    const data = localStorage.getItem(keyName);
    if (!data) {
      return null;
    }

    const item = JSON.parse(data);
    if (!item.hasOwnProperty("ttl")) {
    } else if (Date.now() > item.ttl) {
      for (let key in localStorage) {
        if (key.includes("-30min")) {
          const item = JSON.parse(localStorage.getItem(key));
          if (Date.now() > item.ttl) {
            localStorage.removeItem(key);
          }
        }
      }

      return null;
    }
    return item.value;
  },

  setItem(keyName, keyValue, ttl) {
    if (!ttl) {
      const data = {
        value: keyValue,
      };

      localStorage.setItem(keyName, JSON.stringify(data));
    } else {
      const data = {
        value: keyValue,
        ttl: Date.now() + ttl * 60 * 1000,
      };

      localStorage.setItem(keyName, JSON.stringify(data));
    }
  },
  removeItem(keyName) {
    localStorage.removeItem(keyName);
  },
};
