import React, { useEffect } from "react";
import axios from "axios";
import moment from "moment";

import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useQuery } from "react-query";
import { NGROK } from "../../APIs";
import { useLocation } from "react-router-dom";
import { myLocalStorage } from "../../components/StorageHelper";
import useUserStore from "../../services/userStore";

const Connections = () => {
  const userEmail = useUserStore((state) => state.user.email);
  const location = useLocation();

  const locationState = location?.state || {};

  const { device } = locationState;
  const activeDevice = device || myLocalStorage.getItem("activeComputer");

  const fetchConnections = async (activeDeviceId) => {
    return await axios.get(
      `${NGROK}/api/guacamole/get-history?computerId=${activeDeviceId}`,
    );
  };

  const getToken = async (email, computerId, userRole) => {
    try {
      return await axios.get(
        `${NGROK}/api/access/${userRole}/get-token?email=${email}&computerId=${computerId}`,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const saveToken = async (email, computerId, userRole) => {
    try {
      return await axios.post(`${NGROK}/api/access/${userRole}/save-token`, {
        email,
        computerId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openConnection = async (connection) => {
    const logString = Object.keys(connection.logs)[0];

    const response = await getToken(userEmail, activeDevice.id, "admin");
    const { authToken } = response?.data;

    if (authToken) {
      window.open(
        `https://${activeDevice.guachost}/#/settings/postgresql/recording/${connection.identifier}/${logString}?token=${authToken}`,
        "_blank",
      );
    } else {
      const {
        data: { authToken },
      } = await saveToken(userEmail, activeDevice.id, "admin");

      window.open(
        `https://${activeDevice.guachost}/#/settings/postgresql/recording/${connection.identifier}/${logString}?token=${authToken}`,
        "_blank",
      );
    }
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["connections", { activeDeviceId: activeDevice.id }],
    queryFn: () => fetchConnections(activeDevice.id),
    enabled: !!activeDevice,
  });

  const formattedDuration = (startDate, endDate) => {
    const formattedStartDate = moment(startDate);
    const formattedEndDate = moment(endDate);
    const duration = moment.duration(formattedEndDate.diff(formattedStartDate));

    return `${duration.hours()} : ${duration.minutes()} : ${duration.seconds()}`;
  };

  const connectionsList = data?.data;

  const sortByDate = (connections) => {
    return connections.sort((a, b) => b.startDate - a.startDate);
  };

  const getUserName = (inputString) => {
    var matches = inputString.match(/_(.*?)_/);
    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      console.log("error");
    }
  };

  useEffect(() => {}, [location.state, activeDevice]);

  const checkIfViewIsEnabled = (connection) =>
    Object.keys(connection.logs).length;

  if (isLoading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  if (isError)
    return (
      <Box display={"flex"} p={5}>
        <Typography variant="h6" color="initial">
          Something went wrong during fetching data.
        </Typography>
      </Box>
    );

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
          minWidth: "800px",
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                align="center"
                sx={{ color: "white", fontSize: "16px" }}
              >
                User
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "white", fontSize: "16px" }}
              >
                Start date
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "white", fontSize: "16px" }}
              >
                End date
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Duration
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Remote host
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Recording
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortByDate(connectionsList)?.map((connection) => (
              <TableRow key={connection.identifier}>
                <TableCell align="center" sx={{ fontSize: "16px" }}>
                  {getUserName(connection.username) || connection.username}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "16px" }}>
                  {connection.startDate
                    ? moment(connection.startDate).format("YYYY-MM-DD HH:mm")
                    : "Default time"}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "16px" }}>
                  {connection.endDate
                    ? moment(connection.endDate).format("YYYY-MM-DD HH:mm")
                    : "-"}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "16px" }}>
                  <Button variant="text" color="primary" size="large">
                    {connection.endDate
                      ? formattedDuration(
                          connection.startDate,
                          connection.endDate,
                        )
                      : formattedDuration(connection.startDate, Date.now())}
                  </Button>
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "16px" }}>
                  {connection.remoteHost}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "16px" }}>
                  <Tooltip
                    title={
                      !checkIfViewIsEnabled(connection)
                        ? "Recording was not enabled"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        disabled={!checkIfViewIsEnabled(connection)}
                        variant="outlined"
                        onClick={() => openConnection(connection)}
                      >
                        View
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Connections;
