import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import InviteModal from "./InviteModal";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import axios from "axios";
import { NGROK } from "../../../APIs";
import moment from "moment";
import SearchField from "../logs/components/SearchField";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const UserInvites = () => {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [inviteList, setInviteList] = useState([]);
  const [isSuccessInvite, setIsSuccessInvite] = useState(false);
  const [isErrorInvite, setIsErrorInvite] = useState(false);
  const [successData, setSuccessData] = useState("");
  const [errorData, setErrorData] = useState("");
  const [toggledRow, setToggledRow] = useState(null);

  const openInviteModal = () => {
    setModalOpen(true);
  };

  const handleTenantChange = (value) => {
    setInviteList([]);
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    setInviteList([]);
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const getUserInviteList = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/invite/pending?tenantName=${selectedTenantName}`,
      );
      setInviteList(response.data);
    } catch (error) {
      setInviteList([]);
      console.error(error);
    }
  };

  useEffect(() => {
    getUserInviteList();
  }, [selectedTenantName]);

  const openSuccessInvite = (val) => {
    setIsSuccessInvite(true);
    setIsErrorInvite(false);
    setSuccessData(val);
    setErrorData("");
  };

  const openFailedInvite = (val) => {
    setIsErrorInvite(true);
    setIsSuccessInvite(false);
    setSuccessData("");
    setErrorData(val);
  };

  const handleToggleCode = (id) => {
    setToggledRow((prev) => (prev === id ? null : id));
  };

  const allowedSearchFields = ["firstName", "lastName", "email"];

  const {
    searchTerm,
    filteredData: filteredDevices,
    handleSearch,
  } = useGeneralSearch(inviteList, allowedSearchFields);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {tenantList?.length > 0 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedTenantName ? selectedTenantName : " "}
              options={tenantList.map((tenant) => tenant.tenantName)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Tenant List" />
              )}
              onChange={(e, value) => {
                handleTenantChange(value);
              }}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<InsertInvitationIcon />}
            onClick={openInviteModal}
          >
            Invite Now
          </Button>
        </Box>
      </Box>
      <InviteModal
        openSuccessInvite={openSuccessInvite}
        openFailedInvite={openFailedInvite}
        open={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          getUserInviteList();
        }}
        tenantName={selectedTenantName}
      />
      <Box
        sx={{
          mt: 5,
        }}
      >
        {inviteList.length > 0 ? (
          <>
            <Box
              sx={{
                mb: 5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Pending User Verifications:</Typography>
              <SearchField
                searchTerm={searchTerm}
                handleSearch={handleSearch}
              />
            </Box>

            <TableContainer style={{ height: "fit-content" }} component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  maxWidth: "100%",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">First Name</TableCell>
                    <TableCell align="center">Last Name</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Verification Code</TableCell>
                    <TableCell align="center">CreatedAt</TableCell>
                    <TableCell align="center">ExpiresAt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDevices.map((invite) => (
                    <TableRow key={invite.email}>
                      <TableCell align="center">{invite.firstName}</TableCell>
                      <TableCell align="center">{invite.lastName}</TableCell>
                      <TableCell align="center">{invite.email}</TableCell>
                      <TableCell align="center">
                        <Button onClick={() => handleToggleCode(invite.email)}>
                          {toggledRow === invite.email ? (
                            <>
                              {invite.verificationCode} <LockOpenIcon />
                            </>
                          ) : (
                            <LockIcon />
                          )}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {moment(invite.createdAt).format("DD.MM.YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(invite.expiredAt).format("DD.MM.YYYY HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <Box
              sx={{
                height: "65vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack
                direction={"column"}
                spacing={2}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography variant="body1">
                  Invite users to sign up on Whiteswan Security Platform.
                </Typography>
                <Typography variant="body1">
                  Once they sign up, users will be able to request for JIT
                  access to the servers.
                </Typography>
              </Stack>
            </Box>
          </>
        )}
      </Box>
      {isSuccessInvite && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setIsSuccessInvite(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsSuccessInvite(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {successData}
          </Alert>
        </Snackbar>
      )}
      {isErrorInvite && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setIsErrorInvite(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsErrorInvite(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorData}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default UserInvites;
