import React, { useState } from "react";
import useUserStore from "../../../../services/userStore";

const AccessAWSPolicies = ({ user, groups }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onAWSRequestAccessForm, setOnAWSRequestAccessForm] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { role, userId } = useUserStore((state) => state.user);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClickRequestForm = (polices) => {
    setSelectedPolicies(polices);
    setOnAWSRequestAccessForm(true);
  };

  return <div></div>;
};

export default AccessAWSPolicies;
