import React from "react";
import Alerts from "../../../dashboards/Alerts";

const DeviceAlertsDetails = ({ computerName }) => {
  return (
    <>
      <Alerts fromDetails={true} computerName={computerName} />
    </>
  );
};

export default DeviceAlertsDetails;
