import React, { useState } from "react";

import "../pages.scss";

import ProfilePublishersTable from "../Components/ProfilePublishersTable";
import ProfilesAppsTable from "../Components/ProfilesAppsTable";

const ProfileApplicationsControl = ({
  selectedProfile,
  showCount,
  setShowCount,
  tenantName
}) => {
  const [selectedPublisher, setSelectedPublisher] = useState(undefined);

  return (
    <section className="applicationTab">
      <ProfilePublishersTable
        selectedProfile={selectedProfile}
        selectedPublisher={selectedPublisher}
        setSelectedPublisher={setSelectedPublisher}
      />
      {selectedPublisher ? (
        <ProfilesAppsTable
          selectedPublisher={selectedPublisher}
          selectedProfile={selectedProfile}
          showCount={showCount}
          setShowCount={setShowCount}
          tenantName={tenantName}
        />
      ) : null}
    </section>
  );
};

export default ProfileApplicationsControl;
