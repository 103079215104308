import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { NGROK } from "../../../../APIs";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

const Wrapper = styled.div`
  max-height: 500px;
`;

const useStyles = makeStyles({
  root: {
    maxHeight: "500px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const EC2Details = ({
  selectedDetailsTab,
  handleDetailsTabChange,
  userWithMatchingPolicies,
  groupsWithMatchingPolicies,
  rolesWithMatchingPolicies,
}) => {
  const classes = useStyles();

  const [rolesDetailsModalOpen, setRolesDetailsModalOpen] = useState(false);
  const [rolesPermissionJson, setRolesPermissionJson] = useState([]);
  const [groupsPermissionJson, setGroupsPermissionJson] = useState([]);
  const [groupsDetailsModalOpen, setGroupsDetailsModalOpen] = useState(false);
  const [policiesDetailsModalOpen, setPoliciesDetailsModalOpen] =
    useState(false);
  const [policiesDetails, setPoliciesDetails] = useState([]);

  const fetchPolicyDocDetails = async (policyArns) => {
    const data = {
      policyArn: policyArns,
    };

    try {
      const response = await axios.post(
        `${NGROK}/api/aws-policies/by-arn`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching policy details:", error);
      return null;
    }
  };

  const detailsofGroups = async (groupId) => {
    try {
      const resp = await axios.get(`${NGROK}/api/aws-groups/${groupId}`);
      const data = resp.data;
      const attachedUserPolicies = data?.AttachedPolicies || [];
      const inlineUserPolicies = data?.InlinePolicies || [];
      const consolidatedPolicies = [
        ...inlineUserPolicies,
        ...attachedUserPolicies,
      ];
      console.log(consolidatedPolicies);
      const policyArns = consolidatedPolicies.map((policy) => policy.PolicyArn);
      const policyDocDetails = await fetchPolicyDocDetails(policyArns);
      const { GroupId, GroupName, CreatedAt } = data;
      const formattedPolicies = policyDocDetails.map((policy) => ({
        PolicyId: policy.PolicyId,
        PolicyName: policy.PolicyName,
        PolicyArn: policy.PolicyArn,
        PolicyDocument: policy.PolicyDocument,
      }));
      const combinedJson = [
        {
          GroupId,
          GroupName,
          CreatedAt,
          policies: formattedPolicies,
        },
      ];
      setGroupsPermissionJson(combinedJson);
      setGroupsDetailsModalOpen(true);
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
  };

  const detailsofRoles = async (roleID) => {
    try {
      const resp = await axios.get(`${NGROK}/api/aws-roles/${roleID}`);
      const data = resp.data;
      const attachedUserPolicies = data?.AttachedPermissions || [];
      const inlineUserPolicies = data?.InLinePermissions || [];
      const consolidatedPolicies = [
        ...inlineUserPolicies,
        ...attachedUserPolicies,
      ];

      const policyArns = consolidatedPolicies.map((policy) => policy.PolicyArn);

      const policyDocDetails = await fetchPolicyDocDetails(policyArns);

      const { RoleId, RoleName, CreatedDate } = data;
      const formattedPolicies = policyDocDetails.map((policy) => ({
        PolicyId: policy.PolicyId,
        PolicyName: policy.PolicyName,
        PolicyArn: policy.PolicyArn,
        PolicyDocument: policy.PolicyDocument,
      }));
      const combinedJson = [
        {
          RoleId,
          RoleName,
          CreatedDate,
          policies: formattedPolicies,
        },
      ];
      setRolesPermissionJson(combinedJson);
      setRolesDetailsModalOpen(true);
    } catch (error) {
      console.error("Error fetching role details:", error);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  const filteredPolicies = policiesDetails.filter(
    (policy) =>
      policy.PolicyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      policy.PolicyId.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  return (
    <>
      <Box
        sx={{
          border: "1px solid",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background: "#233044",
            color: "#fff",
            padding: "10px",
            borderRadius: "5px",
            fontSize: "16px",
            p: 4.8,
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          Details
        </Typography>
        <Tabs
          value={selectedDetailsTab}
          onChange={handleDetailsTabChange}
          sx={{ mb: 2, width: "100%" }}
        >
          <Tab
            icon={<PersonIcon />}
            label={<Typography sx={{ fontSize: 16 }}>Users</Typography>}
          />
          <Tab
            icon={<GroupsIcon />}
            label={<Typography sx={{ fontSize: 16 }}>Groups</Typography>}
          />
          <Tab
            icon={<AssignmentIcon />}
            label={<Typography sx={{ fontSize: 16 }}>Roles</Typography>}
          />
        </Tabs>

        {selectedDetailsTab === 0 && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={2}
            sx={{ flexWrap: "wrap", width: "100%" }}
          >
            {userWithMatchingPolicies?.length > 0 ? (
              <List sx={{ width: "100%" }}>
                {userWithMatchingPolicies.map((user) => (
                  <ListItem key={user.UserId}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 16 }}>
                          {user.UserName}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 16 }}>
                          {user.Arn}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                sx={{
                  fontSize: 16,
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                No users can access this resource
              </Typography>
            )}
          </Box>
        )}

        {selectedDetailsTab === 1 && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={2}
            sx={{ flexWrap: "wrap", width: "100%" }}
          >
            {groupsWithMatchingPolicies?.length > 0 ? (
              <List sx={{ width: "100%" }}>
                {groupsWithMatchingPolicies.map((group) => (
                  <ListItem
                    key={group.GroupId}
                    onClick={() => detailsofGroups(group.GroupId)}
                    sx={{ cursor: "pointer" }}
                  >
                    <ListItemIcon>
                      <GroupsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 16 }}>
                          {group.GroupName}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 16 }}>
                          {group.Arn}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                sx={{
                  fontSize: 16,
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                No groups can access this resource
              </Typography>
            )}
          </Box>
        )}

        {selectedDetailsTab === 2 && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={2}
            sx={{ flexWrap: "wrap", width: "100%" }}
          >
            {rolesWithMatchingPolicies?.length > 0 ? (
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  overflowWrap: "break-word",
                }}
              >
                {rolesWithMatchingPolicies.map((role) => (
                  <ListItem
                    key={role.RoleId}
                    onClick={() => detailsofRoles(role.RoleId)}
                    sx={{ cursor: "pointer" }}
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        maxWidth: "80%",
                      }}
                      primary={
                        <Typography sx={{ fontSize: 16 }}>
                          {role.RoleName}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={{
                            fontSize: 16,
                            wordBreak: "break-all",
                          }}
                        >
                          {role.RoleArn}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                sx={{
                  fontSize: 16,
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                No Roles can access this resource
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Dialog
        open={rolesDetailsModalOpen}
        onClose={() => setRolesDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Role Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              {rolesPermissionJson.map((role, index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Role Name: </strong> {role.RoleName}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Role ID: </strong> {role.RoleId}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong> Created Date:</strong> {role.CreatedDate}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    <strong>Policies: </strong>
                  </Typography>
                  <List>
                    {role.policies.map((policy, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={`Policy Name :${policy.PolicyName}`}
                            secondary={`Policy ID: ${policy.PolicyId}`}
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          />
                        </ListItem>
                        <Typography sx={{ fontSize: 16, mt: 2 }}>
                          <strong>Policy Document:</strong>
                        </Typography>
                        {policy.PolicyDocument && (
                          <ListItem
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          >
                            <Paper
                              elevation={3}
                              style={{ padding: "5px", margin: "5px" }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>Version:</strong>{" "}
                                {policy.PolicyDocument.Version}
                              </Typography>
                              {policy.PolicyDocument.Statement.map(
                                (statement, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: 16 }}
                                      >
                                        <strong>Statement {index + 1}:</strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Effect:</strong>{" "}
                                        {statement.Effect}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Action:</strong>{" "}
                                        <List disablePadding>
                                          {statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Resource:</strong>{" "}
                                        {Array.isArray(statement.Resource) ? (
                                          <List>
                                            {statement.Resource.map(
                                              (resource, index) => (
                                                <ListItem
                                                  key={index}
                                                  disableGutters
                                                  sx={{ padding: 0 }}
                                                >
                                                  <ListItemText>
                                                    <Typography
                                                      sx={{ fontSize: 16 }}
                                                    >
                                                      {resource}
                                                    </Typography>
                                                  </ListItemText>
                                                </ListItem>
                                              ),
                                            )}
                                          </List>
                                        ) : (
                                          <Typography sx={{ fontSize: 16 }}>
                                            {statement.Resource}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </Paper>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRolesDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={groupsDetailsModalOpen}
        onClose={() => setGroupsDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Groups Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              {groupsPermissionJson.map((group, index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Group Name: </strong> {group.GroupName}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Group ID: </strong> {group.GroupId}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong> Created Date:</strong> {group.CreatedAt}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    <strong>Policies: </strong>
                  </Typography>
                  <List>
                    {group.policies.map((policy, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={`Policy Name :${policy.PolicyName}`}
                            secondary={`Policy ID: ${policy.PolicyId}`}
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          />
                        </ListItem>
                        <Typography sx={{ fontSize: 16, mt: 2 }}>
                          <strong>Policy Document:</strong>
                        </Typography>
                        {policy.PolicyDocument && (
                          <ListItem
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          >
                            <Paper
                              elevation={3}
                              style={{ padding: "5px", margin: "5px" }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>Version:</strong>{" "}
                                {policy.PolicyDocument.Version}
                              </Typography>
                              {policy.PolicyDocument.Statement.map(
                                (statement, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: 16 }}
                                      >
                                        <strong>Statement {index + 1}:</strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Effect:</strong>{" "}
                                        {statement.Effect}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Action:</strong>{" "}
                                        <List disablePadding>
                                          {statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Resource:</strong>{" "}
                                        {Array.isArray(statement.Resource) ? (
                                          <List>
                                            {statement.Resource.map(
                                              (resource, index) => (
                                                <ListItem
                                                  key={index}
                                                  disableGutters
                                                  sx={{ padding: 0 }}
                                                >
                                                  <ListItemText>
                                                    <Typography
                                                      sx={{ fontSize: 16 }}
                                                    >
                                                      {resource}
                                                    </Typography>
                                                  </ListItemText>
                                                </ListItem>
                                              ),
                                            )}
                                          </List>
                                        ) : (
                                          <Typography sx={{ fontSize: 16 }}>
                                            {statement.Resource}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </Paper>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setGroupsDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EC2Details;
